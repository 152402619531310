<script lang="ts">
	import {onMount} from 'svelte';

	import Hallkart from './hallkart.svelte';
</script>

<div class="page-content">
	<Hallkart />
	<p style="text-align:center">
		Klikk på en bordrad for å velge plass. Plasser markert i rødt er
		opptatte allerede.
	</p>
	<p style="text-align:center">
		Merk deg at elementer i setevelgeren utenom bordradene som f.eks.
		turneringsområde og standplasser kun er grovt plassert, og endringer kan
		forekomme
	</p>
</div>
