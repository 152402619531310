<script lang="ts">
	import {onMount} from 'svelte';

	import {user} from '../stores/user';

	import {getSeatingInfo, selectSeat} from '../api/seatreg';
	import Modal from '../components/Modal.svelte';
	import {tableDefinitions} from '../constants';
	import type {RegistrationData, UserData} from '../global';
	interface PointerEventWithPath extends PointerEvent {
		path?: Element[];
	}

	let open;
	let close;

	$: selectedTable = null;
	$: selectedSeat = null;
	$: selectError = null;

	$: hoverTable = null;
	$: hoverSeat = null;

	let tableSize = 20;

	let tableObject = {};
	let seatingInfo;

	let tables: SVGGElement & Element;
	let hoverelement;

	const tablePrefix = 'table_';
	const seatPrefix = 'seat_';

	function getAge(dateString) {
		let today = new Date();
		let birthDate = new Date(dateString);
		let age = today.getFullYear() - birthDate.getFullYear();
		let m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	const hasPaid = (reg: RegistrationData) => Boolean(reg.payments.length);

	$: isSeatTaken = (table: number, seat: number) => {
		return Boolean(
			seatingInfo.find((e) => e.row === table && e.seat === seat)
		);
	};

	const getSeatInfo = (table: number, seat: number) => {
		if (!seatingInfo) return null;

		const seatInfo = seatingInfo.find(
			(e) => e.row === table && e.seat === seat
		);
		if (!seatInfo) return null;

		return seatInfo;
	};

	$: canChooseSeat = (
		registration: RegistrationData,
		user: UserData,
		row: number,
		seat: number
	): {allowed: boolean; reason?: string} => {
		const ticketType = registration.ticketType;
		const age = getAge(user.birthdate);
		const tableRules = tableDefinitions[row].rules || null;

		if (!hasPaid(registration))
			return {
				allowed: false,
				reason: 'Kun deltakere med betalt billett kan velge sete. Kjøp billett fra din profilside',
			};

		if (!tableRules) return {allowed: true};

		if (tableRules.includes('crew')) {
			if (ticketType !== 0)
				return {
					allowed: false,
					reason: 'Kun crew kan velge sitteplass på dette bordet',
				};
		}
		if (tableRules.includes('16+')) {
			if (age < 16)
				return {
					allowed: false,
					reason: 'Du må være 16+ for å sitte på dette bordet',
				};
		}
		if (tableRules.includes('production')) {
			return {
				allowed: false,
				reason: 'Kun produksjonscrew kan velge sitteplass på dette bordet',
			};
		}

		return {allowed: true};
	};

	const getColorForSeat = (table: number, seat: number) => {
		if (isSeatTaken(table, seat)) {
			return '#ee3030';
		}

		return '#3fe818';
	};

	let onSeatClick = (table: number, seat: number) => {
		if (isSeatTaken(table, seat)) {
			return;
		}
		selectedSeat = seat;
	};

	let onSeatSelection = async () => {
		if (!$user || !$user.registrations) {
			selectError = 'Du må være logget inn for å velge sete';
			return;
		}

		const registration = $user.registrations.find(
			(reg) => reg.eventId === 1
		);
		const {allowed: seatAllowed, reason} = canChooseSeat(
			registration,
			$user,
			selectedTable,
			selectedSeat
		);
		if (!seatAllowed) {
			selectError = reason;
			return;
		}
		if (isSeatTaken(selectedTable, selectedSeat)) {
			selectError = 'This seat is already taken';
			return;
		}

		await selectSeat(registration.id, {
			seat: selectedSeat,
			row: selectedTable,
		});

		await fetchSeatingInfo();

		selectedSeat = null;

		close();
	};

	const fetchSeatingInfo = async () => {
		seatingInfo = await getSeatingInfo();
		tables.childNodes.forEach((table: ChildNode & Element) => {
			let tableName = Number(table.id.substr(tablePrefix.length));
			tableObject[tableName] = {};
			table.childNodes.forEach((seat: ChildNode & Element) => {
				if (!seat.id.includes(seatPrefix)) return;

				let seatName = Number(
					seat.id.substr(seatPrefix.length).split('-')[0]
				);

				tableObject[tableName][seatName] = seat;

				let color = getColorForSeat(tableName, seatName);
				seat.setAttribute('fill', color);
			});
		});
	};

	const onClose = () => {
		selectedTable = null;
		selectedSeat = null;
		selectError = null;
	};

	onMount(async () => {
		//LOAD DATA
		fetchSeatingInfo();

		//HANDLE CLICKS
		tables.addEventListener('click', (e: PointerEventWithPath) => {
			let table = e.path.find(
				(ele) => ele.id && ele.id.includes(tablePrefix)
			);
			let seat = e.path.find(
				(ele) => ele.id && ele.id.includes(seatPrefix)
			);
			if (!table) return;

			let tableName = Number(table.id.substr(tablePrefix.length));
			selectedTable = tableName;

			if (seat) {
				let seatName = Number(
					seat.id.substr(seatPrefix.length).split('-')[0]
				);
				if (!isSeatTaken(tableName, seatName)) {
					selectedSeat = seatName;
				}
			}

			open();
		});
		tables.addEventListener('mouseover', (e: PointerEventWithPath) => {
			let table = e.path.find(
				(ele) => ele.id && ele.id.includes(tablePrefix)
			);
			let seat = e.path.find(
				(ele) => ele.id && ele.id.includes(seatPrefix)
			);

			let tableName = Number(table.id.substr(tablePrefix.length));
			hoverTable = tableName;

			if (seat) {
				let seatName = Number(
					seat.id.substr(seatPrefix.length).split('-')[0]
				);

				hoverTable = tableName;
				hoverSeat = seatName;

				hoverelement.style.display = 'block';
				hoverelement.style.left =
					e.target.getBoundingClientRect().left + 'px';
				hoverelement.style.bottom =
					window.innerHeight -
					e.target.getBoundingClientRect().top +
					'px';
			}
		});

		tables.addEventListener('mouseout', (e: PointerEventWithPath) => {
			let table = e.path.find(
				(ele) => ele.id && ele.id.includes(tablePrefix)
			);
			let seat = e.path.find(
				(ele) => ele.id && ele.id.includes(seatPrefix)
			);

			hoverelement.style.display = 'none';
			hoverTable = null;
			hoverSeat = null;
		});
	});
</script>

<div bind:this={hoverelement} class="elehover">
	{#if hoverSeat !== null}
		{tableDefinitions[hoverTable].letter}{hoverSeat + 1}<br />
		{#if getSeatInfo(hoverTable, hoverSeat)}
			Nick: {getSeatInfo(hoverTable, hoverSeat)?.user?.gamertag}
		{:else}
			Status: Ledig
		{/if}
	{/if}
</div>

<svg width="100%" viewBox="0 0 6200 4600">
	<defs>
		<linearGradient
			id="linear-gradient"
			x1="0.477"
			x2="0.489"
			gradientUnits="objectBoundingBox"
		>
			<stop offset="0" stop-color="#659fe2" />
			<stop offset="1" stop-color="#e26565" />
		</linearGradient>
		<clipPath id="clip-custom_1">
			<rect width="6200" height="4600" />
		</clipPath>
	</defs>
	<rect width="6200" height="4600" fill="#fff" />
	<rect
		id="Rectangle_270"
		data-name="Rectangle 270"
		width="1566"
		height="1384"
		transform="translate(4634 3216)"
		fill="#bcbcbc"
	/>
	<path
		id="Rectangle_270_-_Outline"
		data-name="Rectangle 270 - Outline"
		d="M7,7V1377H1559V7H7M0,0H1566V1384H0Z"
		transform="translate(4634 3216)"
		fill="#707070"
	/>
	<g id="tables" bind:this={tables}>
		<g id="table_103" transform="translate(5874.316 -4709) rotate(90)">
			<g
				id="Group_26"
				data-name="Group 26"
				transform="translate(2545 7036.75) rotate(-90)"
			>
				<rect
					id="Rectangle_214"
					data-name="Rectangle 214"
					width="567.867"
					height="600"
					transform="translate(6500 2780)"
					fill="#659fe2"
				/>
				<text
					id="FOH-2"
					data-name="FOH"
					transform="translate(6794.57 2978.5) rotate(90)"
					font-size="100"
					font-family="SegoeUI-Bold, Segoe UI"
					font-weight="700"><tspan x="0" y="0">FOH</tspan></text
				>
			</g>

			<rect
				id="seat_0-104"
				data-name="Rectangle 275"
				width="161"
				height="101"
				transform="translate(5367 336.75)"
				fill="#fff"
			/>
			<rect
				id="seat_2-104"
				data-name="Rectangle 273"
				width="161"
				height="101"
				transform="translate(5723 336.75)"
				fill="#fff"
			/>

			<rect
				id="seat_1-104"
				data-name="Rectangle 274"
				width="161"
				height="101"
				transform="translate(5545 336.75)"
				fill="#fff"
			/>
		</g>
		<!-- <g id="table_102" transform="translate(4817 3306)">
			<rect
				id="Rectangle_16"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="CREW_C"
				data-name="CREW C"
				transform="translate(446.685 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">CREW C</tspan></text
			>
			<rect id="seat_0" width="120" height="100" fill="#d8d8d8" />
			<rect
				id="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g> -->
		<g id="table_101" transform="translate(4817 3533)">
			<rect
				id="Rectangle_16-2"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-2"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="CREW_B"
				data-name="CREW B"
				transform="translate(446.685 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">CREW B</tspan></text
			>
			<rect
				id="seat_0-2"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-2"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-2"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-2"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-2"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-2"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-2"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-2"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-2"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-2"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-2"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-2"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-2"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-2"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-2"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-2"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-2"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-2"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-2"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-2"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_100" transform="translate(4817 3960)">
			<rect
				id="Rectangle_16-3"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-3"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="CREW_A"
				data-name="CREW A"
				transform="translate(446.685 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">CREW A</tspan></text
			>
			<rect
				id="seat_0-3"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-3"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-3"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-3"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-3"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-3"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-3"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-3"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-3"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-3"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-3"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-3"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-3"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-3"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-3"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-3"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-3"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-3"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-3"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-3"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<!-- <g id="table_15" transform="translate(1250 113)">
			<rect
				id="Rectangle_16-4"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-4"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_P"
				data-name="BORD P"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD P</tspan></text
			>
			<rect
				id="seat_0-4"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-4"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-4"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-4"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-4"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-4"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-4"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-4"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-4"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-4"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-4"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-4"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-4"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-4"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-4"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-4"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-4"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-4"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-4"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-4"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g> -->
		<g id="table_13" transform="translate(1250 300)">
			<rect
				id="Rectangle_16-5"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-5"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_N"
				data-name="BORD N"
				transform="translate(300 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD N (16+)</tspan></text
			>
			<rect
				id="seat_0-5"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-5"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-5"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-5"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-5"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-5"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-5"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-5"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-5"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-5"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-5"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-5"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-5"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-5"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-5"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-5"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-5"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-5"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-5"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-5"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_12" transform="translate(1250 850)">
			<rect
				id="Rectangle_16-6"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-6"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_M"
				data-name="BORD M"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD M</tspan></text
			>
			<rect
				id="seat_0-6"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-6"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-6"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-6"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-6"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-6"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-6"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-6"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-6"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-6"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-6"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-6"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-6"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-6"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-6"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-6"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-6"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-6"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-6"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-6"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_11" transform="translate(1250 1400)">
			<rect
				id="Rectangle_16-7"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-7"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_L"
				data-name="BORD L"
				transform="translate(446.685 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD L</tspan></text
			>
			<rect
				id="seat_0-7"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-7"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-7"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-7"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-7"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-7"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-7"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-7"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-7"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-7"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-7"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-7"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-7"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-7"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-7"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-7"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-7"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-7"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-7"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-7"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_10" transform="translate(1250 2250)">
			<rect
				id="Rectangle_16-8"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-8"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_K"
				data-name="BORD K"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD K</tspan></text
			>
			<rect
				id="seat_0-8"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-8"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-8"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-8"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-8"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-8"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-8"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-8"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-8"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-8"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-8"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-8"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-8"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-8"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-8"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-8"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-8"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-8"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-8"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-8"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_9" transform="translate(1250 2800)">
			<rect
				id="Rectangle_16-9"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-9"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_J"
				data-name="BORD J"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD J</tspan></text
			>
			<rect
				id="seat_0-9"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-9"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-9"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-9"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-9"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-9"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-9"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-9"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-9"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-9"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-9"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-9"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-9"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-9"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-9"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-9"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-9"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-9"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-9"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-9"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_8" transform="translate(1250 3350)">
			<rect
				id="Rectangle_16-10"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-10"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_I"
				data-name="BORD I"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD I</tspan></text
			>
			<rect
				id="seat_0-10"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-10"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-10"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-10"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-10"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-10"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-10"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-10"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-10"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-10"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-10"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-10"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-10"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-10"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-10"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-10"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-10"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-10"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-10"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-10"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_7" transform="translate(1250 3900)">
			<rect
				id="Rectangle_16-11"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-11"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_H"
				data-name="BORD H"
				transform="translate(446.685 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD H</tspan></text
			>
			<rect
				id="seat_0-11"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-11"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-11"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-11"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-11"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-11"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-11"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-11"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-11"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-11"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-11"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-11"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-11"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-11"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-11"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-11"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-11"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-11"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-11"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-11"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<!-- <g id="table_7" transform="translate(2720 113)">
			<rect
				id="Rectangle_16-12"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-12"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_H"
				data-name="BORD H"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD H</tspan></text
			>
			<rect
				id="seat_0-12"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-12"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-12"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-12"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-12"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-12"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-12"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-12"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-12"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-12"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-12"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-12"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-12"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-12"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-12"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-12"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-12"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-12"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-12"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-12"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g> -->
		<g id="table_6" transform="translate(2720 300)">
			<rect
				id="Rectangle_16-13"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-13"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_G"
				data-name="BORD G"
				transform="translate(320 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD G (16+)</tspan></text
			>
			<rect
				id="seat_0-13"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-13"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-13"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-13"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-13"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-13"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-13"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-13"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-13"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-13"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-13"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-13"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-13"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-13"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-13"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-13"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-13"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-13"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-13"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-13"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_5" transform="translate(2720 850)">
			<rect
				id="Rectangle_16-14"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-14"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_F"
				data-name="BORD F"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD F</tspan></text
			>
			<rect
				id="seat_0-14"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-14"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-14"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-14"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-14"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-14"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-14"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-14"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-14"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-14"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-14"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-14"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-14"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-14"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-14"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-14"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-14"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-14"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-14"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-14"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_4" transform="translate(2720 1400)">
			<rect
				id="Rectangle_16-15"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-15"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_E"
				data-name="BORD E"
				transform="translate(446.685 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD E</tspan></text
			>
			<rect
				id="seat_0-15"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-15"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-15"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-15"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-15"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-15"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-15"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-15"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-15"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-15"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-15"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-15"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-15"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-15"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-15"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-15"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-15"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-15"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-15"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-15"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_3" transform="translate(2720 2250)">
			<rect
				id="Rectangle_16-16"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-16"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_D"
				data-name="BORD D"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD D</tspan></text
			>
			<rect
				id="seat_0-16"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-16"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-16"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-16"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-16"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-16"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-16"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-16"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-16"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-16"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-16"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-16"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-16"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-16"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-16"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-16"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-16"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-16"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-16"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-16"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_2" transform="translate(2720 2800)">
			<rect
				id="Rectangle_16-17"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-17"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_C"
				data-name="BORD C"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD C</tspan></text
			>
			<rect
				id="seat_0-17"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-17"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-17"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-17"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-17"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-17"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-17"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-17"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-17"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-17"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-17"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-17"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-17"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-17"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-17"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-17"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-17"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-17"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-17"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-17"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_1" transform="translate(2720 3350)">
			<rect
				id="Rectangle_16-18"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-18"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_B"
				data-name="BORD B"
				transform="translate(445.556 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD B</tspan></text
			>
			<rect
				id="seat_0-18"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-18"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-18"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-18"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-18"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-18"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-18"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-18"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-18"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-18"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-18"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-18"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-18"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-18"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-18"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-18"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-18"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-18"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-18"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-18"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
		<g id="table_0" transform="translate(2720 3900)">
			<rect
				id="Rectangle_16-19"
				data-name="Rectangle 16"
				width="1200"
				height="120"
				transform="translate(0 100)"
				fill="#3a3a3a"
			/>
			<path
				id="Rectangle_16_-_Outline-19"
				data-name="Rectangle 16 - Outline"
				d="M1,1V119H1199V1H1M0,0H1200V120H0Z"
				transform="translate(0 100)"
				fill="#707070"
			/>
			<text
				id="BORD_A"
				data-name="BORD A"
				transform="translate(446.685 192)"
				fill="#fff"
				font-size="90"
				font-family="SegoeUI, Segoe UI"
				><tspan x="0" y="0">BORD A</tspan></text
			>
			<rect
				id="seat_0-19"
				data-name="seat_0"
				width="120"
				height="100"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_1-19"
				data-name="seat_1"
				width="120"
				height="100"
				transform="translate(120)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_2-19"
				data-name="seat_2"
				width="120"
				height="100"
				transform="translate(240)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_3-19"
				data-name="seat_3"
				width="120"
				height="100"
				transform="translate(360)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_4-19"
				data-name="seat_4"
				width="120"
				height="100"
				transform="translate(480)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_5-19"
				data-name="seat_5"
				width="120"
				height="100"
				transform="translate(600)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_6-19"
				data-name="seat_6"
				width="120"
				height="100"
				transform="translate(720)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_7-19"
				data-name="seat_7"
				width="120"
				height="100"
				transform="translate(840)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_8-19"
				data-name="seat_8"
				width="120"
				height="100"
				transform="translate(960)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_9-19"
				data-name="seat_9"
				width="120"
				height="100"
				transform="translate(1080)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_10-19"
				data-name="seat_10"
				width="120"
				height="100"
				transform="translate(0 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_11-19"
				data-name="seat_11"
				width="120"
				height="100"
				transform="translate(120 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_12-19"
				data-name="seat_12"
				width="120"
				height="100"
				transform="translate(240 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_13-19"
				data-name="seat_13"
				width="120"
				height="100"
				transform="translate(360 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_14-19"
				data-name="seat_14"
				width="120"
				height="100"
				transform="translate(480 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_15-19"
				data-name="seat_15"
				width="120"
				height="100"
				transform="translate(600 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_16-19"
				data-name="seat_16"
				width="120"
				height="100"
				transform="translate(720 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_17-19"
				data-name="seat_17"
				width="120"
				height="100"
				transform="translate(840 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_18-19"
				data-name="seat_18"
				width="120"
				height="100"
				transform="translate(960 220)"
				fill="#d8d8d8"
			/>
			<rect
				id="seat_19-19"
				data-name="seat_19"
				width="120"
				height="100"
				transform="translate(1080 220)"
				fill="#d8d8d8"
			/>
		</g>
	</g>
	<g id="stands" transform="translate(30, 0)">
		<g id="stand_4">
			<rect
				id="Rectangle_315"
				data-name="Rectangle 315"
				width="300"
				height="300"
				transform="translate(231 922.21)"
				fill="#e26565"
			/>
			<text
				id="STAND_5"
				data-name="STAND 5"
				transform="translate(248.432 976.811) rotate(45)"
				font-size="80"
				font-family="SegoeUI-Bold, Segoe UI"
				font-weight="700"><tspan x="0" y="0">STAND 5</tspan></text
			>
		</g>
		<g id="stand_3">
			<rect
				id="Rectangle_314"
				data-name="Rectangle 314"
				width="300"
				height="300"
				transform="translate(231 1256.63)"
				fill="#e26565"
			/>
			<text
				id="STAND_4-2"
				data-name="STAND 4"
				transform="translate(248.432 1312.231) rotate(45)"
				font-size="80"
				font-family="SegoeUI-Bold, Segoe UI"
				font-weight="700"><tspan x="0" y="0">STAND 4</tspan></text
			>
		</g>
		<g id="stand_2">
			<rect
				id="Rectangle_278"
				data-name="Rectangle 278"
				width="300"
				height="300"
				transform="translate(231 1593.05)"
				fill="#e26565"
			/>
			<text
				id="STAND_3-2"
				data-name="STAND 3"
				transform="translate(248.432 1647.651) rotate(45)"
				font-size="80"
				font-family="SegoeUI-Bold, Segoe UI"
				font-weight="700"><tspan x="0" y="0">STAND 3</tspan></text
			>
		</g>
		<g id="stand_1">
			<rect
				id="Rectangle_277"
				data-name="Rectangle 277"
				width="300"
				height="600"
				transform="translate(231 1918.05)"
				fill="#e26565"
			/>
			<text
				id="STAND_2-2"
				data-name="STAND 2"
				transform="translate(339.5 2007.05) rotate(90)"
				font-size="100"
				font-family="SegoeUI-Bold, Segoe UI"
				font-weight="700"><tspan x="0" y="0">STAND 2</tspan></text
			>
		</g>
		<g id="stand_0">
			<rect
				id="Rectangle_284"
				data-name="Rectangle 284"
				width="900"
				height="600"
				transform="translate(540 2549.05) rotate(90)"
				fill="#e26565"
			/>
			<text
				id="Elite"
				transform="translate(215 2740.05) rotate(90)"
				font-size="130"
				font-family="SegoeUI-Bold, Segoe UI"
				font-weight="700"><tspan x="0" y="0">STAND 1</tspan></text
			>
		</g>
		<g id="stand_storage">
			<rect
				id="Rectangle_316"
				data-name="Rectangle 316"
				width="200"
				height="1600"
				transform="translate(0 922.05)"
				fill="#3d3d3d"
			/>
			<path
				id="Rectangle_316_-_Outline"
				data-name="Rectangle 316 - Outline"
				d="M1,1V1599H199V1H1M0,0H200V1600H0Z"
				transform="translate(0 922.05)"
				fill="#707070"
			/>
			<!-- <text
			id="Lagring_for_stands"
			data-name="Lagring for stands"
			transform="translate(67 1371.05) rotate(90)"
			fill="#fff"
			font-size="80"
			font-family="SegoeUI-Bold, Segoe UI"
			font-weight="700"
			><tspan x="0" y="0">Lagring for stands</tspan></text
		> -->
		</g>
	</g>
	<g id="stage">
		<!-- <rect
			id="Rectangle_293"
			data-name="Rectangle 293"
			width="161"
			height="880"
			transform="translate(5233 1520)"
			fill="#fff"
		/> -->
		<g
			id="Group_19"
			data-name="Group 19"
			transform="translate(-1170 -287.75)"
		>
			<rect
				id="Rectangle_218"
				data-name="Rectangle 218"
				width="100"
				height="1200"
				transform="translate(6798 1650)"
				fill="#676767"
			/>
			<rect
				id="Rectangle_1"
				data-name="Rectangle 1"
				width="300"
				height="1200"
				transform="translate(6500 1650)"
			/>
			<text
				id="SCENE"
				transform="translate(6580 1880) rotate(90)"
				fill="#fff"
				font-size="240"
				font-family="SegoeUI-Bold, Segoe UI"
				font-weight="700"><tspan x="0" y="0">SCENE</tspan></text
			>
			<!-- <rect
				id="Rectangle_215"
				data-name="Rectangle 215"
				width="100"
				height="200"
				transform="translate(6401 1650)"
			/> -->
			<!-- <rect
				id="Rectangle_216"
				data-name="Rectangle 216"
				width="100"
				height="200"
				transform="translate(6401 2650)"
			/> -->
			<rect
				id="Rectangle_217"
				data-name="Rectangle 217"
				width="100"
				height="800"
				transform="translate(6798 1850)"
			/>
		</g>
	</g>
	<text
		id="LAN_PARTY"
		data-name="LAN PARTY"
		transform="translate(1698 2100)"
		font-size="300"
		font-family="SegoeUI-Bold, Segoe UI"
		font-weight="700"><tspan x="0" y="0">LAN PARTY</tspan></text
	>
	<text
		id="TEKNISK SONE"
		data-name="TEKNISK SONE"
		transform="translate(5250 350)"
		font-size="100"
		font-family="SegoeUI-Bold, Segoe UI"
		font-weight="700"><tspan x="0" y="0">TEKNISK SONE</tspan></text
	>

	<g id="info" transform="translate(-776 1850)">
		<rect
			id="Rectangle_283"
			data-name="Rectangle 283"
			width="480"
			height="223"
			transform="translate(5410 1370)"
			fill="url(#linear-gradient)"
		/>
		<text
			id="INFO-2"
			data-name="INFO"
			transform="translate(5651 1527)"
			font-size="110"
			font-family="SegoeUI-Bold, Segoe UI"
			font-weight="700"><tspan x="-131.189" y="0">INFO</tspan></text
		>
	</g>
	<g id="console">
		<path
			id="Line_2"
			data-name="Line 2"
			d="M7.5,756h-15V0h15Z"
			transform="translate(5002.5 0.5)"
			fill="#707070"
		/>
		<g id="Group_32" data-name="Group 32" transform="translate(2620 -3900)">
			<g
				id="Group_29"
				data-name="Group 29"
				transform="translate(-3072.669 598)"
			>
				<rect
					id="Rectangle_214-2"
					data-name="Rectangle 214"
					width="900"
					height="600"
					transform="translate(4474.669 3302)"
					fill="#659fe2"
				/>
			</g>
			<text
				id="KONSOLL_TURNERINGER"
				data-name="KONSOLLTURNERINGER"
				transform="translate(1852 4144)"
				font-size="110"
				font-family="SegoeUI-Bold, Segoe UI"
				font-weight="700"
				><tspan x="-248.037" y="0">KONSOLL</tspan><tspan
					x="-381.697"
					y="147">TURNERINGER</tspan
				></text
			>
		</g>
	</g>
	<g id="sleeping" transform="translate(0 14)">
		<rect
			id="Rectangle_288"
			data-name="Rectangle 288"
			width="964"
			height="176"
			transform="translate(2811 4410)"
			fill="#e2de65"
		/>
		<text
			id="SOVESAL"
			transform="translate(3111 4559)"
			font-size="85"
			font-family="SegoeUI-Bold, Segoe UI"
			font-weight="700"><tspan x="0" y="0">SOVESAL</tspan></text
		>
		<text
			id="INNGANG_TIL"
			data-name="INNGANG TIL"
			transform="translate(3293 4487)"
			font-size="70"
			font-family="SegoeUI-Bold, Segoe UI"
			font-weight="700"
			><tspan x="-227.568" y="0">INNGANG TIL</tspan></text
		>
	</g>
	<!-- <g id="projectors">
		<path
			id="Line_11"
			data-name="Line 11"
			d="M157.881,375.1l-167-371L9.119-4.1l167,371Z"
			transform="translate(5111.5 891.75)"
			fill="#e26565"
		/>
		<path
			id="Line_12"
			data-name="Line 12"
			d="M9.119,375.1-9.119,366.9l167-371L176.119,4.1Z"
			transform="translate(5111.5 2462.25)"
			fill="#e26565"
		/>
		<path
			id="Line_13"
			data-name="Line 13"
			d="M.232,377.443l-.463-.886,721-377,.464.886Z"
			transform="translate(5195.5 700.5)"
			fill="#e26565"
		/>
		<path
			id="Line_14"
			data-name="Line 14"
			d="M579.805,245.461l-580-245L.195-.461l580,245Z"
			transform="translate(5195.5 2656.5)"
			fill="#e26565"
		/>
	</g> -->
	<!-- <g id="storage">
		<rect
			id="Rectangle_290"
			data-name="Rectangle 290"
			width="321"
			height="1954"
			transform="translate(5879 1262)"
			fill="#3d3d3d"
		/>
		<path
			id="Rectangle_290_-_Outline"
			data-name="Rectangle 290 - Outline"
			d="M7,7V1947H314V7H7M0,0H321V1954H0Z"
			transform="translate(5879 1262)"
			fill="#707070"
		/>
		<text
			id="LAGRING_AV_DIVERSE"
			data-name="LAGRING AV DIVERSE"
			transform="translate(6008 1623) rotate(90)"
			fill="#fff"
			font-size="120"
			font-family="SegoeUI-Bold, Segoe UI"
			font-weight="700"
			><tspan x="0" y="0">LAGRING AV DIVERSE</tspan></text
		>
	</g> -->
	<g id="entry">
		<text
			id="INNGANG"
			transform="translate(62 4462)"
			font-size="150"
			font-family="SegoeUI-Bold, Segoe UI"
			font-weight="700"><tspan x="0" y="0">INNGANG</tspan></text
		>
		<path
			id="Line_9"
			data-name="Line 9"
			d="M5,854H-5V0H5Z"
			transform="translate(863.5 3746.5)"
			fill="#707070"
		/>
		<g id="Group_46" data-name="Group 46">
			<rect
				id="Rectangle_212"
				data-name="Rectangle 212"
				width="864"
				height="76"
				transform="translate(0 4525)"
				fill="#e2de65"
			/>
			<text
				id="SECURITY"
				transform="translate(194 4597)"
				font-size="100"
				font-family="SegoeUI-Bold, Segoe UI"
				font-weight="700"><tspan x="0" y="0">SECURITY</tspan></text
			>
		</g>
	</g>
</svg>

<Modal
	bind:open
	bind:close
	{onClose}
	title={selectedTable !== null && tableDefinitions[selectedTable].name}
>
	<p>Velg din plass:</p>
	<div class="table">
		{#each Array(tableDefinitions[selectedTable].tableSize || tableSize) as _, i}
			<div
				class="seat"
				class:taken={isSeatTaken(selectedTable, i)}
				class:selected={selectedSeat === i}
				on:click={() => onSeatClick(selectedTable, i)}
			>
				{tableDefinitions[selectedTable].letter}{i + 1}
			</div>
		{/each}
	</div>
	{#if selectedSeat !== null}
		<p>
			Valgt sete: {tableDefinitions[selectedTable].letter}{selectedSeat +
				1}
		</p>
	{/if}
	{#if selectError}
		<p class="error">
			{selectError}
		</p>
	{/if}
	<button class="save-button" on:click={onSeatSelection}>
		Lagre setevalg
	</button>
</Modal>

<style>
	.error {
		color: red;
	}

	.elehover {
		pointer-events: none;
		position: fixed;
		padding: 5px;
		color: black;
		background-color: #fff;
		border: solid 1px #ccc;
	}

	#tables:hover {
		cursor: pointer;
	}
	#tables rect:hover {
		filter: brightness(1.2);
	}

	.table {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	.seat {
		font-size: 14px;
		color: white;
		background: rgb(49, 184, 19);
		width: 10%;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: solid 1px #999;
	}
	.seat.taken {
		background-color: #ee3030;
	}
	.seat.selected {
		background-color: rgb(40, 140, 233);
	}
	.seat:hover {
		cursor: pointer;
		filter: brightness(1.1);
	}

	.save-button {
		width: 50%;
		padding: 10px;
		margin: 10px auto;
		border: none;
		background: rgb(40, 140, 233);
	}
</style>
