import { writable } from 'svelte/store';
import { getMyUserProfile, updateMyUserProfile } from '../api/user';
import { token } from './token';
function createUser() {
    const { subscribe, set, update } = writable(null);
    const fetchMyUserProfile = async () => {
        try {
            const userProfile = await getMyUserProfile();
            return set(userProfile);
        }
        catch (e) {
            if (e.error === 'Invalid token') {
                token.set(null);
                return set(null);
            }
        }
    };
    return {
        subscribe,
        fetch: fetchMyUserProfile,
        change: async (userInfo) => {
            await updateMyUserProfile(userInfo);
            fetchMyUserProfile();
        },
    };
}
export const user = createUser();
