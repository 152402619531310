<div class="page-content">
	<h1>Kjøpsbetingelser</h1>
	<h2>TGEX sine retningslinjer for kjøp</h2>
	<p>
		TGEX jobber aktivt for å kunne sikre din tilgang til arrangement på en
		trygg og enkel måte. TGEX er en teknisk leverandør av billettjenesten og
		arrangør for arrangementet. TGEX godkjenner eventuelle søknader om
		refusjon samt setter regler for videresalg. Når du legger inn en ordre
		gjennom TGEX.no aksepterer du våre vilkår for billettkjøp.
	</p>
	<h2>Kostnader for handel av billetter</h2>
	<p>
		Kostnaden du betaler for en handel avhenger av prisen på billettene og
		hvilken betalingsmåte som velges.
	</p>
	<h2>Tilgjengelige betalingsmåter</h2>
	<p>
		TGEX jobber for å gi deg gode betalingsalternativer for å gjøre det så
		enkelt og sikkert som mulig for deg å handle billetter hos oss. Vi
		tilbyr betaling med bankkort på nett via Stripe, manuell bankoverføring
		etter avtale, eller betaling i døren mot en ekstrakostnad.
	</p>
	<p>
		All kommunikasjon mellom din datamaskin og våre tjenere er beskyttet med
		kryptering (SSL/TLS). Passord og annen sensitiv informasjon er lagret
		kryptert slik at ingen skal kunne misbruke den. Vi lagrer ikke
		betalingsinformasjon som for eksempel kredittkortnummer.
	</p>
	<h2>Bruk og refundering av billetter</h2>
	<p>
		Alle billetter som du kjøper, vil ha deg som eier. Du kan, om ønskelig,
		overføre bruksretten for en billett til en annen person, som da vil
		kunne gi bruksretten videre til en annen person igjen. Vi forstår at man
		til tider kan havne opp i situasjoner som gjør at man ikke kan benytte
		seg av billetter. TGEX vil ikke refundere billetter som er betalte. For
		alle slike henvendelser referer vi til arrangøren.
	</p>
	<p>
		Uventede hendelser kan skje, og i ytterste konsekvens kan arrangøren
		avlyse eller flytte arrangementet. Dersom et arrangement blir avlyst
		refunderes billetten etter arrangørens bestemmelser, og senest 65 dager
		etter arrangementets opprinnelige dato. Serviceavgifter vil ikke bli
		refundert. Dersom et arrangement får ny dato eller blir flyttet til ny
		lokasjon vil dine billetter fremdeles være gyldige, og kan benyttes som
		normalt til ny dato.
	</p>
	<h2>Sletting av billetter</h2>
	<p>
		Dersom TGEX er i tvil om at brukerinformasjon opplyst stemmer,
		forbeholder de seg retten til å kontakte deg ved å bruke
		kontaktinformasjonen du har skrevet inn. Dersom det ikke lykkes å få
		kontakt med deg, kan billettene bli slettet uten videre varsel og betalt
		beløp refundert til din konto/ditt kredittkort.
	</p>
	<h2>Angrerett</h2>
	<p>
		Angrerettsloven gjelder ikke ved kjøp av billetter fra TGEX. Lovens
		kapittel fem paragraf 19 omhandler begrensninger i loven:
	</p>
	<p>
		<i
			>«Ved annet fjernsalg gjelder angreretten ikke for enkeltstående
			tjenester dersom selgeren ved avtaleinngåelsen forplikter seg til å
			levere tjenesten på et bestemt tidspunkt eller indenfor et bestemt
			tidsrom»</i
		>
	</p>
	<p>
		Enkeltstående tjenester har blitt tolket av myndighetene til å gjelde
		for eksempel underholdningsarrangement som teater, kino, konsert og
		datatreff.
	</p>
	<h2>Utstedelse av billetter</h2>
	<p>
		Din billett vil være digital og knyttes til brukeren din på TGEX.no ved
		billettkjøp. Før arrangementet, eller i døren vil du kunne hente
		adgangsarmbånd mot at gyldig legitimasjon fremvises.
	</p>
	<h2>Plassreservasjon</h2>
	<p>
		I de tilfeller et arrangement har åpen plassreservasjon kan du, når det
		er åpnet for det, reservere en eller flere plasser. TGEX forbeholder seg
		retten til å flytte eller endre plassene. Merk at andre objekter på
		kartet, som for eksempel scene, bare er retningsgivende og kan være
		annerledes enn indikert på kartet.
	</p>
	<h2>Privat bruksrett</h2>
	<p>
		Ved handel av billetter fra TGEX aksepterer du at disse billettene bare
		kan benyttes til privat bruk. Uten spesiell tillatelse fra TGEX kan du
		ikke distribuere, endre, publisere eller bruke billettene eller
		informasjon fra nettstedet bortsett fra til privat bruk.
	</p>
	<p>
		Brukere på TGEX er personlige og kan ikke overdras til andre (Men du kan
		selge videre din billett for under kjøpspris om du tar kontakt med TGEX
		på forhånd). Hver person kan kun ha en bruker. Dersom et arrangement
		selger billetter ved loddtrekning eller køordning, regnes det som forsøk
		på omgåelse av denne ordningen ved å ha flere brukere. Ved forsøk på
		omgåelse av køordning og/eller lotteri, kan TGEX utestenge deg fra
		billettkjøp eller deltakelse fra arrangement..
	</p>
	<p>
		Bruk av verktøy for å overvåke eller implisitt forstyrre driften av
		nettstedet er forbudt. TGEX forbeholder seg retten til å ekskludere deg
		fra nettstedet uten forutgående varsel ved brudd på vilkår for bruk.
	</p>
	<h2>Juridiske parter</h2>
	<p>
		TGEX, Tromsø Gaming Experience og TGEX.no er markedsnavn for TGEX SA.
		Kunden og Kjøperen som omtalt på sidene er personen som handler
		billetten(e). Arrangøren er den juridiske enheten som er kontraktspunkt
		for alle salg, inkludert levering av produkter som er kjøpt.
	</p>
	<h2>Persondata og kontaktinformasjon</h2>
	<p>
		TGEX har fokus på personvern, og oppbevarer all data på norske maskiner
		for å sikre at informasjon bare dekkes av norsk lovgivning. Du kan selv
		påvirke hvilken informasjon om deg som vises på systemet ved å velge
		personvernnivå, tilgjengelig under "Rediger profil".
	</p>
	<p>
		TGEX deler ikke din persondata med andre parter enn de du velger å
		knytte deg til i TGEX ved å være kjøper eller bruker av billett til et
		arrangement. I de tilfellene vil arrangøren kunne se persondata som
		navn, bosted, kontaktinformasjon og fødselsdato men ikke data som
		logginformasjon, hvilke andre arrangement du har kjøpt billetter til med
		mer.
	</p>
</div>
