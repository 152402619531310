<div class="page-content">
	<h1>Informasjon til foreldre og foresatte</h1>
	<p>
		Denne siden viser til informasjon for foreldre og foresatte av barn og
		ungdom som ønsker å delta på Tromsø Gaming Experience.&nbsp;
	</p>
	<p>
		TGEX er et arrangement laget for data, konsoll og teknologi-interesserte
		i Tromsø, hvor betalende kan ta med eget utstyr for å v&aelig;re sosial
		og spille med likesinnede, altså et LAN. Arrangementet er et tiltak vi
		gjennomfører for å samle data og konsoll interesserte ut av hjemmet, til
		et arrangement hvor de kan møte nye venner og ha gode opplevelser
		sammen.
	</p>
	<p>
		Vi i TGEX tilrettelegger for at vårt arrangement skal v&aelig;re både
		hyggelig og trygt for våre deltakere. Dette betyr blant annet at vi har
		nulltoleranse mot rusmidler, og at vi ikke tolererer noen form for
		mobbing eller annen alvorlig uenighet. Vårt Crew består av myndige
		personer som står for både vakthold og trivsel under arrangementet, og
		sørger for at ingen uvedkommende får adgang til hallen. Dette vil bidra
		til at alle påmeldte får en trygg og hyggelig opplevelse på vårt
		arrangement.<br /><br />
	</p>
	<p>Noen praktiske detaljer og tips!</p>
	<ul>
		<li>
			TGEX har egen kiosk på arrangementet, hvor deltakerne kan kjøpe
			mineralvann og varmmat. Fredag og lørdag har vi også kafé. Les mer
			om
			<a href="/kiosk"> kiosken </a>
		</li>
		<li>
			Dere som foreldre har fri tilgang til hallen for å se til deres
			håpefulle. <br />Hjelp gjerne til under opp og nedrigg, gi beskjed
			om dette til vakten i døra.
		</li>
		<li>
			Skriv ned hvor ungdommene sitter slik at det blir enkelt for dere å
			finne dem om noe skulle skje. Lagre gjerne telefonnummeret til
			venner eller andre som skal sitte sammen.
		</li>
		<li>
			Under arrangementet setter vi av områder hvor deltakere kan slappe
			av og sove dersom noen ønsker å gjøre dette utenfor bord-områdene.
			Garderober vil også v&aelig;re tilgjengelig dersom noen føler behov
			for forbedring av personlig hygiene.
		</li>
		<li>
			Dersom din ungdom bruker faste reseptbelagte medikamenter, sørg for
			at dette er pakket med og i original embalsje med navn.
		</li>
		<li>
			Vi ønsker også at dere som foreldre og foresatte gjør unge og ungdom
			som planlegger å besøke vårt arrangement oppmerksom på viktigheten
			av å få i seg nok mat og søvn, og viktigheten av personlig hygiene.
		</li>
		<li>
			Sørg for at din ungdom har lest og forstått våre regler og
			sikkerhetsinformasjon i forkant av arrangementet.
		</li>
		<li>
			<b>Vi tar smittevern på alvor</b> og vil legge ut et eget skriv om smittevern
			når det er klart
		</li>
	</ul>
	<br />
	<p>
		Vi ønsker alle spill, data, konsoll og teknologiinteresserte velkommen
		til vårt arrangement!
	</p>
	<p>
		Spørsmål vedrørende arrangementet kan sendes til vår facebookside, eller
		rettes til:<br />Brynjar Johansen på epost:
		<a href="mailto:brynjar@tgex.no">brynjar@tgex.no</a> , eller trykk
		<a href="/about#contact">her</a>.
	</p>
</div>
