<script lang="ts">
	import {token} from '../stores/token';
	import {user} from '../stores/user';
	import UserRegistrationForm from '../components/forms/UserRegistrationForm.svelte';
	import LoginForm from '../components/forms/LoginForm.svelte';
	import {onMount} from 'svelte';

	export let onLogin = () => {};

	let isLoggingIn = false;
	let isRegistering = false;

	onMount(async () => {
		if ($token) {
			await user.fetch();
		}
	});

	const chooseLogin = () => {
		isLoggingIn = true;
		isRegistering = false;
	};

	const chooseRegisterUser = () => {
		isLoggingIn = false;
		isRegistering = true;
	};

	const logout = () => {
		isLoggingIn = false;
		isRegistering = false;

		$token = null;
	};

	const onLoginCalled = async () => {
		await user.fetch();

		onLogin();
	};
</script>

{#if !$token}
	{#if !isRegistering && !isLoggingIn}
		<p>Du må være logget inn for å fullføre skjemaet</p>
		<p>
			Har du en bruker fra tidligere? Velg logg inn.<br /> Hvis ikke,
			vennligst registrer en ny bruker<br />
			<span class="remark">
				(Dette er et nytt system og brukere fra tidligere år finnes
				ikke)
			</span>
		</p>
		<button class="action-button" on:click={chooseLogin}> Logg inn </button>
		eller
		<button class="action-button neutral" on:click={chooseRegisterUser}>
			Registrer bruker
		</button>
	{:else if isLoggingIn}
		<h2>Logg inn</h2>
		<p>
			Har du ikke en bruker?
			<span class="link" on:click={chooseRegisterUser}
				>Registrer ny bruker</span
			>
		</p>
		<LoginForm onLogin={onLoginCalled} />
	{:else if isRegistering}
		<h2>Registrer bruker</h2>
		<p>
			Har du allerede en bruker?
			<span class="link" on:click={chooseLogin}>Logg inn</span>
		</p>
		<UserRegistrationForm onLogin={onLoginCalled} />
	{/if}
{:else}
	{#if $user}
		<div class="logged-in-as">
			<p>
				<b>
					Logget inn som
					<a href="/profile" target="_blank">
						{#if $user.gamertag}(<b>{$user.gamertag}</b>){/if}
						{$user.firstName}

						{$user.lastName}
					</a>
				</b>
			</p>
			<p>
				Ikke deg? <button class="link-button" on:click={logout}
					>Bytt bruker</button
				>
			</p>
		</div>
	{/if}
	<!-- Inject the content hidden behind login wall -->
	<slot />
{/if}

<style>
	.logged-in-as {
		border-left: solid 3px #66e757;
		padding: 5px 10px;
		margin-bottom: 30px;
	}

	.logged-in-as p {
		margin: 5px 0px;
	}

	a,
	.link {
		color: #45a0e8;
	}
	.link:hover {
		cursor: pointer;
	}

	.link-button {
		color: #45a0e8;
		background: none;
		border: none;
		font-size: inherit;
	}
	.link-button:hover {
		cursor: pointer;
	}

	.action-button {
		display: inline-block;
		background-color: #67cd5b;
		padding: 9px 16px;
		border: none;
		color: #fff;
		border-radius: 8px;
		white-space: nowrap;
		margin: 3px 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		transition: all 0.2s;
	}
	.action-button.neutral {
		background-color: #45a0e8;
	}
	.action-button:hover {
		cursor: pointer;
		background-color: #66e757;
	}
	.action-button.neutral:hover {
		background-color: #45a0e8;
	}
</style>
