import { remove, get, post, update } from './api';
export const deleteActivity = async (activityId) => {
    return remove(`/activity/${activityId}`);
};
export const getActivities = async () => {
    return get(`/activities`);
};
export const postActivity = async (activity) => {
    return post('/activity', {
        name: activity.name,
        content: activity.content,
    });
};
export const updateActivity = async (activity) => {
    return update('/activity/' + activity.id, {
        name: activity.name,
    });
};
