import { get, post } from './api';
export const getApplications = async () => {
    return get(`/crew/applications`);
};
export const postApplication = async (applicationData) => {
    return post('/crew/application', applicationData);
};
export const acceptApplication = async (applicationId, acceptedCrewType, reviewReason) => {
    return post(`/crew/application/${applicationId}/accept`, {
        acceptedCrewType,
        reviewReason,
    });
};
export const rejectApplication = async (applicationId, reviewReason, blockFromCrew) => {
    return post(`/crew/application/${applicationId}/reject`, {
        reviewReason,
        blockFromCrew,
    });
};
