<script lang="ts">
	import {Route} from 'tinro';

	import Home from './pages/home.svelte';
	import Activities from './pages/activities.svelte';
	import Activity from './pages/activity.svelte';
	import News from './pages/news.svelte';
	import Article from './pages/article.svelte';
	import Header from './components/header.svelte';
	import Registration from './pages/registration.svelte';
	import Login from './pages/login.svelte';
	import Profile from './pages/profile.svelte';
	import Logout from './pages/logout.svelte';
	import JoinCrew from './pages/join-crew.svelte';
	import Foreldre from './pages/foreldre.svelte';
	import Praktiskinfo from './pages/practicalinfo.svelte';
	import Participants from './pages/participants.svelte';
	import About from './pages/about.svelte';
	import Kiosk from './pages/kiosk.svelte';
	import Crewcontact from './pages/crewcontact.svelte';
	import Crewapplications from './pages/crewapplications.svelte';
	import Admincrew from './pages/admincrew.svelte';
	import Adminparticipants from './pages/adminparticipants.svelte';
	import Participantprofile from './pages/participantprofile.svelte';
	import Forgotpassword from './pages/forgotpassword.svelte';
	import Resetpassword from './pages/resetpassword.svelte';
	import Seatreg from './pages/seatreg.svelte';
	import Rules from './pages/rules.svelte';
	import Tos from './pages/tos.svelte';
	import Crew from './pages/crew.svelte';
</script>

<Header />
<Route path="/*" firstmatch>
	<Route path="/"><Home /></Route>
	<Route path="/foreldre"><Foreldre /></Route>
	<Route path="/practical-info"><Praktiskinfo /></Route>
	<Route path="/rules"><Rules /></Route>
	<Route path="/kjopsbetingelser"><Tos /></Route>
	<Route path="/kiosk"><Kiosk /></Route>
	<Route path="/about"><About /></Route>
	<Route path="/parents"><Foreldre /></Route>
	<Route path="/participants"><Participants /></Route>
	<Route path="/crew"><Crew /></Route>
	<Route path="/login"><Login /></Route>
	<Route path="/logout"><Logout /></Route>
	<Route path="/profile"><Profile /></Route>
	<Route path="/forgot-password"><Forgotpassword /></Route>
	<Route path="/reset-password"><Resetpassword /></Route>
	<Route path="/registration"><Registration /></Route>
	<Route path="/join-crew"><JoinCrew /></Route>
	<Route path="/seatreg"><Seatreg /></Route>

	<Route path="/admin/*">
		<Route path="/applications"><Crewapplications /></Route>
		<Route path="/crew"><Admincrew /></Route>
		<Route path="/participants"><Adminparticipants /></Route>
		<Route path="/participants/:participantId" let:meta>
			<Participantprofile
				participantId={Number(meta.params.participantId)}
			/>
		</Route>
	</Route>
	<Route path="/crew/*">
		<Route path="/contact"><Crewcontact /></Route>
	</Route>
	<Route path="/activity/*">
		<Route path="/"><Activities /></Route>
		<Route path="/:activityId" let:meta>
			<Activity activityId={Number(meta.params.activityId)} />
		</Route>
	</Route>
	<Route path="/news/*">
		<Route path="/"><News /></Route>
		<Route path="/:articleId" let:meta>
			<Article articleId={Number(meta.params.articleId)} />
		</Route>
	</Route>
</Route>

<style>
</style>
