<script lang="ts">
	import {activities} from '../stores/activities';

	export let activityId: number;

	activities.fetch();

	$: activity = $activities.find(
		(elem) => Number(elem.id) === Number(activityId)
	);
</script>

<div class="page-content">
	{#if activity}
		<h1 class="activity-heading">{activity.name}</h1>
		<p class="activity-label">AKTIVITET</p>
		<div class="activity-content content">
			{@html activity.content}
		</div>
		<br /><br />
	{/if}
</div>
