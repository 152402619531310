<script lang="ts">
	import Countdown from './Countdown.svelte';
	import {token} from '../stores/token';
	import {user} from '../stores/user';
	import {event} from '../stores/event';
	import {getNextEvent} from '../api/event';

	if ($token) {
		user.fetch();
	}
	event.fetch();
	let nextEvent;
	const getEventInfo = async () => {
		nextEvent = await getNextEvent();
	};
	$: nextEvent = nextEvent;
	getEventInfo();

	$: loggedIn = $user && $token;

	let shouldShowMenu = false;

	let showMenu = () => (shouldShowMenu = true);
	let hideMenu = () => (shouldShowMenu = false);

	let y: number;
</script>

<svelte:window bind:scrollY={y} />

<header class:background={y > 0}>
	<div class="header-content">
		<button
			class="menu-button material-icons"
			class:visible={!shouldShowMenu}
			on:click={showMenu}>menu</button
		>
		<button
			class="menu-button material-icons"
			class:visible={shouldShowMenu}
			on:click={hideMenu}>close</button
		>
		<div class="header-left">
			<a href="/">
				<div class="header__logo-wrapper">
					<img
						class="header__logo"
						src="/assets/logo.png"
						alt="TGEX Logo"
					/>
				</div>
			</a>
			<div class="main-nav" class:visible={shouldShowMenu}>
				<a
					on:click={hideMenu}
					class="main-nav__link show-mobile"
					href="/"
				>
					<p>Hjem</p>
				</a>
				<a on:click={hideMenu} class="main-nav__link" href="/news">
					<p>Nyheter</p>
				</a>
				<div class="main-nav__link">
					<p class="hide-mobile">Informasjon</p>

					<div class="main-nav__dropdown">
						<a on:click={hideMenu} href="/about"> Om TGEX </a>
						<a on:click={hideMenu} href="/parents">
							Informasjon til foreldre
						</a>
						<a on:click={hideMenu} href="/practical-info">
							Praktisk info
						</a>
						<a on:click={hideMenu} href="/rules"> Reglement </a>
						<a on:click={hideMenu} href="/kiosk"> Kiosk og Kafé </a>
						<a on:click={hideMenu} href="/participants">Påmeldte</a>
						<a on:click={hideMenu} href="/crew">Crew</a>
						<a on:click={hideMenu} href="/seatreg">Setevalg</a>
					</div>
				</div>
				{#if !loggedIn || !$user.crew.length || !$user.registrations.length}
					<div class="main-nav__separator" />
				{/if}
				{#if !loggedIn || !$user.crew.length}
					<a
						on:click={hideMenu}
						class="main-nav__link"
						href="/join-crew"
					>
						<p>Bli med i Crew</p>
					</a>
				{/if}
				{#if !loggedIn || !$user.registrations.length}
					<a
						on:click={hideMenu}
						class="main-nav__link"
						href="registration"
					>
						<p>Påmelding</p>
					</a>
				{/if}
				<div class="main-nav__separator" />
				{#if loggedIn}
					{#if $user.crew.length}
						<div class="main-nav__link">
							<p class="hide-mobile">Crewsider</p>
							<div class="main-nav__dropdown">
								<a on:click={hideMenu} href="/crew/contact">
									Crewkontakt
								</a>
								{#if $user.admin}
									<a
										on:click={hideMenu}
										href="/admin/applications"
									>
										Se crewsøknader
									</a>
									<a on:click={hideMenu} href="/admin/crew">
										Administrer Crew
									</a>
									<a
										on:click={hideMenu}
										href="/admin/participants"
									>
										Administrer Påmeldte
									</a>
								{/if}
							</div>
						</div>
					{/if}
					<a
						on:click={hideMenu}
						class="main-nav__link"
						href="/profile"
					>
						<p>Profil</p>
					</a>
					<a
						on:click={hideMenu}
						class="main-nav__link"
						href="/logout"
					>
						<p class="redlink">Logg ut</p>
					</a>
				{:else}
					<a on:click={hideMenu} class="main-nav__link" href="/login">
						<p>Logg inn</p>
					</a>
				{/if}
			</div>
		</div>
		<div class="header-right">
			<Countdown event={nextEvent} />
		</div>
	</div>
</header>

<style>
	.redlink {
		color: #e03939;
	}
	.redlink:hover {
		color: #a72020;
	}

	.menu-button {
		display: none;
	}

	img {
		max-width: 100%;
	}

	a {
		text-decoration: none;
		color: #3070dd;
	}

	header {
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		transition: background-color 0.2s;
	}
	header.background {
		background-color: #070707aa;
	}

	.header-content {
		width: 100%;
		max-width: 1400px;
		margin: 0 auto;

		color: white;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	.header-left {
		color: white;
		display: flex;
		align-items: flex-start;
	}
	.main-nav {
		color: white;
		display: flex;
		align-items: flex-start;
	}
	.header-right {
		margin-top: 10px;
		color: white;
		display: flex;
		align-items: flex-start;
		padding-right: 40px;
	}

	.header__logo-wrapper {
		width: 150px;
		padding-top: 10px;
		margin-right: 50px;
	}
	.header__logo-wrapper:hover {
		cursor: pointer;
	}
	.header__logo-wrapper:hover .header__logo {
		transform: translateY(0px) scale(1.05);
		filter: brightness(120%);
	}
	.header__logo-wrapper:active .header__logo {
		transform: translateY(1px) scale(1.03);
		filter: brightness(120%);
	}

	.header__logo {
		transition: all 0.12s;
		height: 80px;
		margin-right: 30px;
		margin-left: 30px;
	}

	.main-nav__link {
		color: #eeeeee;
		text-decoration: none;
		font-size: 18px;
		padding-left: 20px;
		padding-right: 20px;
		border-top: solid 4px transparent;
	}

	.main-nav__link p {
		margin-top: 30px;
		transition: transform 0.2s;
	}

	.main-nav__link:hover {
		cursor: pointer;
	}
	.main-nav__link:hover p {
		color: rgb(255, 255, 255);
		transform: translateY(-2px);
	}
	.main-nav__link:active p {
		transform: translateY(0px) scale(0.95);
	}

	.main-nav__dropdown {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.main-nav__dropdown a {
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}

	/* Change color of dropdown links on hover */
	.main-nav__dropdown a:hover {
		background-color: #f1f1f1;
	}

	/* Show the dropdown menu on hover */
	.main-nav__link:hover .main-nav__dropdown {
		display: block;
	}

	.main-nav__separator {
		border-right: solid 1px #eeeeee;
		border-radius: 10px;
		height: 30px;
		margin-top: 30px;
		margin-right: 20px;
		margin-left: 20px;
	}

	.show-mobile {
		display: none;
	}

	@media screen and (max-width: 1200px) {
		.header-right {
			display: none;
		}
	}

	@media screen and (max-width: 1000px) {
		.show-mobile {
			display: initial;
		}
		.hide-mobile {
			display: none;
		}
		header.background {
			background-color: #070707ee;
		}
		header {
			background-color: #070707ee;
			display: block;
			position: sticky;
			top: 0;
			max-height: 100vh;
			overflow-y: auto;
		}
		.main-nav {
			display: none;
		}
		.main-nav.visible {
			display: flex;
		}
		.menu-button {
			background: none;
			border: none;
			position: fixed;
			color: white;
			font-size: 36px;
			top: 20px;
			left: 10px;
			width: 40px;
			height: 40px;
		}
		.menu-button.visible {
			display: block;
		}

		.header__logo {
			margin-top: 10px;
			margin-bottom: 10px;
			height: 50px;
			display: inline-block;
		}
		.header__logo-wrapper {
			width: auto;
			margin: 0;
		}
		.main-nav,
		.header-left {
			width: 100%;
			align-items: center;
			text-align: center;
			flex-direction: column;
		}

		.main-nav__link p {
			margin-top: 0;
		}
		.main-nav__separator {
			margin-top: 0;
			margin-bottom: 20px;
			border-bottom: solid 1px white;
			height: 0;
			width: 70px;
		}

		.main-nav__dropdown {
			display: block;
			position: relative;
			background: none;
			color: white;
		}

		.main-nav__dropdown a {
			color: white;
			padding: 0 0 20px 0;
			text-decoration: none;
			display: block;
		}
		.main-nav__dropdown a:hover {
			background: none;
		}
	}
</style>
