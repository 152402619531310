import { basePost as post } from './api';
export const register = async (user) => {
    return post('/register', user);
};
export const login = async (user) => {
    return post('/login', {
        email: user.email,
        password: user.password,
        method: 0,
    });
};
export const forgotPassword = async (email) => {
    return post('/forgotpassword', {
        email,
    });
};
export const resetPassword = async (user) => {
    return post('/resetpassword', {
        token: user.token,
        password: user.password,
    });
};
