<script lang="ts">
	interface VideoElement {
		muted: boolean;
		volume: number;
	}

	let videoElement: Element & VideoElement;
	export let videoFocused = false;
	let volume = 0.5;

	const focusVideo = () => {
		videoFocused = true;
		videoElement.volume = volume;
		videoElement.muted = false;
	};

	const unfocusVideo = () => {
		videoFocused = false;
		videoElement.muted = true;
	};
</script>

<div class="video-container" id="video-container" class:focused={videoFocused}>
	<video
		class="video"
		id="bgvideo"
		playsinline
		autoplay
		muted
		loop
		bind:this={videoElement}
	>
		<source
			src="https://static.tgex.no/aftermovie-v2.mp4"
			type="video/mp4"
		/>
	</video>
	<div class="video__controls">
		<div class="video__controls-top" />
		<div class="video__controls-center">
			<img class="video__logo" src="assets/logo.png" alt="TGEX Logo" />
			<h1 class="tagline">LAN. GAMING. SOCIAL.</h1>
			<button class="play-button" on:click={focusVideo}>
				<img src="assets/play.svg" alt="" />
			</button>
		</div>
		<div class="video__controls-bottom">
			<button class="play-button" on:click={unfocusVideo}>
				<img
					class="play-button__rotated"
					src="assets/play.svg"
					alt=""
				/>
			</button>
		</div>
	</div>
</div>

<style>
	.video-container {
		position: relative;
		height: 56.25%;
		margin-bottom: calc(-20%);
		overflow: hidden;
		transition: all 0.2s;
	}
	.video-container.focused {
		height: 100vh;
		margin-bottom: 0vh;
	}

	.video-container.focused .video__controls-center {
		opacity: 0;
	}
	.video-container.focused .video__controls-bottom {
		opacity: 1;
	}

	.video-container.focused .play-button {
		transform: rotate(-90deg);
	}

	@media screen and (max-width: 1100px) {
		.video-container {
			height: auto;
			margin-bottom: 20px;
		}
		.video-container.focused {
			height: calc(100vh - 200px);
			margin-bottom: 0vh;
		}
	}
</style>
