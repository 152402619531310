<script lang="ts">
	import {news} from '../stores/news';

	export let articleId: number;

	news.fetch();

	$: article = $news.find((elem) => Number(elem.id) === Number(articleId));
</script>

<div class="page-content">
	{#if article}
		<h1 class="activity-heading">{article.title}</h1>
		<p class="activity-label">NYHETSSAK</p>
		<div class="activity-content content">
			<div class="excerpt">{article.excerpt}</div>
			<br />
			<div>{@html article.content}</div>
		</div>
		<br /><br />
	{/if}
</div>
