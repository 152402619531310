<div class="page-content">
	<h1>Kiosk og kafé</h1>
	<p>
		På TGEX har vi selvfølgelig en døgnåpen kiosk tilgjengelig rett utenfor
		hallen! Vi selger alt fra varmmat som pølser, et stort utvalg
		mineralvann, til potetgull og andre søtsaker. Kiosken tar imot betaling
		med både kort og kontant, men vi oppfordrer til bruk av kort eller
		vipps. På fredag og lørdag åpner vi kafè i foajeen i tredje etasje. Her
		kan deltakerne kjøpe seg skikkelig middag.
	</p>
	<p>Liste over varer og meny kommer nærmere arrangementet!</p>
	<p>
		<i>Merk at det ikke er anledning til å varme medbragt mat i hallen</i>
	</p>

	<h2>KAFÈ Åpningstider:</h2>
	<p>Fredag og lørdag 17:00 – 20:00</p>
	<p><b>Meny:</b></p>
	<ul>
		<li>Hamburger 90,-</li>
		<li>Hamburger med pommes frites 120,-</li>
		<li>Nuggets (7 stk) 35,-</li>
		<li>Nuggets med pommes frites 65,-</li>
		<li>Pommes frites 35,-</li>
		<li>Nachos 100,-</li>
		<li>Kyllingkarriwraps 40,-</li>
		<li>Vegetarwraps 35,-</li>
	</ul>
	<h2>KIOSKEN</h2>
	<h3>SNACKS</h3>
	<ul>
		<li>Cheese Doodles 30,-</li>
		<li>Sørlandschips Paprika 35,-</li>
		<li>Sørlandschips Havsalt 35,-</li>
		<li>Potetskruer 25,-</li>
		<li>Safari 25,-</li>
	</ul>
	<b>SJOKOLADE</b>
	<ul>
		<li>Melkerull 25,-</li>
		<li>Kvikklunsj 15,-</li>
		<li>Crispo 25,-</li>
		<li>Stratos 25,-</li>
		<li>Smash 40,-</li>
		<li>GODTERI</li>
		<li>Sour Mix Stripes 20,-</li>
		<li>Jordbærstenger 20,-</li>
		<li>Minimix 20,-</li>
	</ul>
	<b>DRIKKE</b>
	<ul>
		<li>Mineralvann/brus 35,-</li>
		<li>Burn/Monster 35,-</li>
		<li>Redbull 25,-</li>
		<li>Vann 20,-</li>
		<li>Kaffe 10,-</li>
	</ul>
	<b>VARMMAT</b>
	<ul>
		<li>Knorr Bolognese 30,-</li>
		<li>Knorr Carbonara 30,-</li>
		<li>Pølse i brød 25,-</li>
		<li>Landgang 35,-</li>
		<li>Landgang med salat og dressing 40,-</li>
		<li>Hel Grandiosa 70,-</li>
		<li>Halv Grandiosa 35,-</li>
	</ul>
	<b>VARMMAT MENY</b>
	<ul>
		<li>Pølse og mineralvann 45,-</li>
		<li>Landgang og mineralvann 55,-</li>
		<li>Landgang med salat og dressing, og mineralvann 60,-</li>
		<li>Hel Grandiosa og mineralvann 90,-</li>
		<li>Halv Grandiosa og mineralvann 55,-</li>
	</ul>

</div>
