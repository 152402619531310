<script lang="ts">
	import type {CrewApplicationData, RegistrationData} from '../global';
	import {CrewType, TicketType} from '../constants';

	import {postApplication} from '../api/crewapplication';
	import RequireUser from '../components/RequireUser.svelte';
	import {getNextEvent} from '../api/event';

	$: registerError = '';

	let acceptsTerms = false;
	let registrationCompleted = false;

	const crewApplication: CrewApplicationData & RegistrationData = {
		ticketType: TicketType.Crew,
		public: true,
		consoles: '',
		bringsOwnChair: false,
		wantsNewsletter: false,
		wantedCrew: null,
		wantedCrew2: null,
		wantedCrew3: null,
		clothingSize: null,
		hasDriversLicense: false,
		previousExperience: '',
		goodAt: '',
		specialConsiderations: '',
	};

	const sizes = {
		xs: 'XS',
		s: 'S',
		m: 'M',
		l: 'L',
		xl: 'XL',
		xxl: '2XL',
		xxxl: '3XL',
		xxxxl: '4XL',
		xxxxxl: '5XL',
	};

	const submitApplication = async () => {
		registerError = '';
		if (!acceptsTerms) {
			registerError =
				'Du må godkjenne reglementet for å registrere din påmelding';
			return;
		}
		try {
			await postApplication(crewApplication);
		} catch (e) {
			registerError = e.error;
			return;
		}
		registrationCompleted = true;
	};

	let nextEvent;
	const getEventInfo = async () => {
		nextEvent = await getNextEvent();
	};
	$: nextEvent = nextEvent;
	getEventInfo();
</script>

{#if nextEvent}
	<div class="page-content">
		<h1>{nextEvent.name} - Crewsøknad</h1>
		{#if registrationCompleted}
			<div class="registered">
				<p>
					Din crewsøknad for TGEX er registrert! Et medlem av
					admingruppen vil vurdere søknaden din fortløpende og du vil
					få svar på epost når den er vurdert.
				</p>
				<p>
					Du kan alltid se status for din søknad fra <a
						href="/profile"
					>
						din profil
					</a>
				</p>
			</div>
		{:else}
			<h2>Crew</h2>
			<label>
				Ønsket crew:
				<select required bind:value={crewApplication.wantedCrew}>
					<option>Velg crew</option>
					<option value={CrewType.Security}>Security</option>
					<option value={CrewType.Kiosk}>Kiosk</option>
					<option value={CrewType.Tech}>Tech</option>
					<option value={CrewType.Game}>Game</option>
					<option value={CrewType.Entertainment}>Entertainment</option
					>
				</select>
			</label>
			<label>
				Ønsket crew (Andrevalg): - Ikke påkrevd
				<select bind:value={crewApplication.wantedCrew2}>
					<option>Velg crew</option>
					<option value={CrewType.Security}>Security</option>
					<option value={CrewType.Kiosk}>Kiosk</option>
					<option value={CrewType.Tech}>Tech</option>
					<option value={CrewType.Game}>Game</option>
					<option value={CrewType.Entertainment}>Entertainment</option
					>
				</select>
			</label>
			<label>
				Ønsket crew (Tredjevalg): - Ikke påkrevd
				<select bind:value={crewApplication.wantedCrew3}>
					<option>Velg crew</option>
					<option value={CrewType.Security}>Security</option>
					<option value={CrewType.Kiosk}>Kiosk</option>
					<option value={CrewType.Tech}>Tech</option>
					<option value={CrewType.Game}>Game</option>
					<option value={CrewType.Entertainment}>Entertainment</option
					>
				</select>
			</label>
			<label>
				Tidligere LAN-, eller annen relevant erfaring:
				<textarea
					bind:value={crewApplication.previousExperience}
					cols="50"
					rows="5"
				/>
			</label>
			<label>
				Jeg er dyktig på:
				<textarea
					bind:value={crewApplication.goodAt}
					cols="50"
					rows="5"
				/>
			</label>
			<label>
				Er det noen spesielle hensyn vi må ta?<br />
				<span class="remark">
					(Matallergener, Uønskede arbeidsoppgaver, etc.)
				</span>
				<textarea
					bind:value={crewApplication.specialConsiderations}
					cols="50"
					rows="5"
				/>
			</label>
			<br />
			<h3>Praktisk info</h3>
			<label>
				<input
					type="checkbox"
					bind:checked={crewApplication.hasDriversLicense}
				/> Jeg har førerkort klasse B
			</label>
			<label>
				Størrelse på T-skjorte
				<select bind:value={crewApplication.clothingSize}>
					<option>Velg størrelse</option>
					{#each Object.entries(sizes) as [value, name]}
						<option {value}>{name}</option>
					{/each}
				</select>
			</label>
			<br />

			<RequireUser>
				<h2>Annet</h2>
				<label>
					<input
						type="checkbox"
						name="wants_newsletter"
						id="wants_newsletter"
						bind:checked={crewApplication.wantsNewsletter}
					/> Jeg vil motta nyhetsbrev fra TGEX.
				</label>
				Uavhengig av denne innstillingen vil du få epost vedrørende din brukerprofil,
				søknadsstatus og én oppdatering når billettbetaling åpner
				<br /><br />
				<label>
					<input
						type="checkbox"
						name="accepts_terms"
						id="accepts_terms"
						bind:checked={acceptsTerms}
						required
					/>
					Jeg samtykker til TGEX sitt
					<a href="/reglement" target="_blank">reglement</a>
					og
					<a href="/privacy" target="_blank">personvernsregler</a>
				</label>
				<br />
				{#if registerError}
					<p class="error">{registerError}</p>
				{/if}
				<button
					class="action-button neutral"
					on:click={submitApplication}
				>
					Send søknad
				</button>
			</RequireUser>
		{/if}
		<br /><br />
	</div>
{/if}

<style>
	p {
		max-width: 1200px;
		margin-top: 0;
	}

	h2,
	h3 {
		margin: 0;
	}

	.error {
		color: red;
	}

	.link {
		color: #45a0e8;
	}
	.link:hover {
		cursor: pointer;
	}

	.remark {
		font-style: italic;
		font-size: 14px;
	}

	label {
		display: block;
	}

	input[type='text'],
	input[type='tel'],
	input[type='number'],
	input[type='date'],
	input[type='email'],
	input[type='password'],
	select,
	textarea {
		display: block;
		padding: 10px 20px;
		font-size: 16px;
		border-radius: 5px;
		border: none;
		min-width: 270px;
		margin-top: 2px;
		margin-bottom: 10px;
	}

	input[type='checkbox'] {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.action-button {
		display: inline-block;
		background-color: #67cd5b;
		padding: 9px 16px;
		border: none;
		color: #fff;
		border-radius: 8px;
		white-space: nowrap;
		margin: 3px 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		transition: all 0.2s;
	}
	.action-button.neutral {
		background-color: #45a0e8;
	}
	.action-button:hover {
		cursor: pointer;
		background-color: #66e757;
	}
	.action-button.neutral:hover {
		background-color: #45a0e8;
	}
</style>
