import { writable } from 'svelte/store';
import { getEvent } from '../api/event';
function createEvent() {
    const { subscribe, set } = writable(null);
    const fetchEvent = async () => {
        const event = await getEvent();
        return set(event);
    };
    return {
        subscribe,
        fetch: fetchEvent,
    };
}
export const event = createEvent();
