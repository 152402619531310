<script lang="ts">
	import type {EventData} from '../global';

	function createCountdown() {
		updateCountdown();
		return setInterval(function () {
			updateCountdown();
		}, 1000);
	}

	export let event: EventData;

	let days: number;
	let hours: number;
	let minutes: number;
	let seconds: number;

	let text = 'starter om';

	function updateCountdown() {
		let now = new Date();
		let targetDate = new Date(event?.startsAt);

		if (now.valueOf() > targetDate.valueOf()) {
			text = 'slutter om';
			targetDate = new Date(event?.endsAt);
			if (now.valueOf() > targetDate.valueOf()) {
				text = 'er over';
				targetDate = new Date(event?.endsAt);
			}
		}

		const isValid =
			targetDate instanceof Date && !isNaN(targetDate.valueOf());

		if (!isValid) {
			days = 0;
			hours = 0;
			minutes = 0;
			seconds = 0;
			return;
		}

		let targetSeconds = Math.floor(targetDate.valueOf() / 1000);
		let nowSeconds = Math.floor(now.valueOf() / 1000);

		let secondsUntil = targetSeconds - nowSeconds;

		days = Math.floor(secondsUntil / 60 / 60 / 24);
		let daysInSeconds = days * 24 * 60 * 60;
		hours = Math.floor((secondsUntil - daysInSeconds) / 60 / 60);
		let hoursInSeconds = hours * 60 * 60;
		minutes = Math.floor(
			(secondsUntil - daysInSeconds - hoursInSeconds) / 60
		);
		let minutesInSeconds = minutes * 60;
		seconds =
			secondsUntil - daysInSeconds - hoursInSeconds - minutesInSeconds;
	}

	createCountdown();
</script>

<div>
	<p class="countdown-heading">{event?.name || ''} {text}</p>
	<div class="segments">
		<div class="countdown-segment">
			<p class="countdown-number" id="countdown1-days">{days}</p>
			<p class="countdown-label">days</p>
		</div>
		<div class="countdown-segment">
			<p class="countdown-number" id="countdown1-hours">{hours}</p>
			<p class="countdown-label">hours</p>
		</div>
		<div class="countdown-segment">
			<p class="countdown-number" id="countdown1-minutes">{minutes}</p>
			<p class="countdown-label">mins</p>
		</div>
		<div class="countdown-segment">
			<p class="countdown-number" id="countdown1-seconds">{seconds}</p>
			<p class="countdown-label">secs</p>
		</div>
	</div>
	<p class="timestamp">9. - 12. mars 2023</p>
</div>

<style>
	.timestamp {
		font-family: 'Bebas Neue', sans-serif;
		font-size: 18px;
		letter-spacing: 1px;
		margin: 0;
		color: #ddd;
	}
	.segments {
		display: flex;
	}

	.countdown-segment {
		padding-right: 20px;
		text-align: center;
	}
	.countdown-heading {
		letter-spacing: 1px;
		font-family: 'Bebas Neue', sans-serif;
		color: #ddd;
		margin: 0;
		font-size: 18px;
	}
	.countdown-number {
		margin: 0;
		font-size: 25px;
		font-family: 'Bebas Neue', sans-serif;
	}
	.countdown-label {
		margin: 0;
		font-size: 18px;
		font-family: 'Bebas Neue', sans-serif;
	}
</style>
