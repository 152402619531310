<div class="page-content">
	<h1>PRAKTISK INFORMASJON</h1>
	<p>
		<b>HVA: </b>TGEX - Tromsø Gaming Experience, er et LAN arrangement for
		ungdom i alle aldre med interesse for spilling, esport, teknologi og alt
		som hører med. Deltakerne får mulighet til å ta med sin egen PC en hel
		helg i TUIL-hallen. Arrangementet er non-profit og baserer all inntekt
		på deltakeravgift og støtte fra lokalt og nasjonalt næringsliv. TGEX har
		nulltolleranse mot rusmidler, usportslig opptreden, mobbing og annen
		alvorlig uenighet.
	</p>
	<p>
		<b>NÅR</b>: Årets TGEX arrangement er fra Torsdag 9. mars, klokken 21:00
		til Søndag 12. mars, klokken 13:00. Denne helgen er en del av
		vinterferien for skolene i 2023 for Nord-Norge.<br />
	</p>
	<p>
		<b>HVOR:</b> I TUIL-hallen. Arthur Arntzens Veg 24, 9020 Tromsdalen.
		Inngang A. Følg skilting mot hovedinngang. Hallen har gratis
		døgnparkering.<br />
	</p>
	<p>
		<b>PRIS:</b> Adgang til TGEX LAN koster 550,- pr. deltaker.<br />
		Besøksbillett koster kr 50,-. Dette gir ikke adgang til å ta med sin egen
		PC eller konsoll, men man kan komme og gå så mye man ønsker i løpet av hele
		arrangementet. Man kan bruke PC/spillkonsoller til venner. Besøkende kan
		kun delta i våre spillkonsoll konkurranser.<br /><br />
		<b>* Merk at kjøpte billetter ikke refunderes *</b>
	</p>
	<br />
	<h3><b>DU TRENGER</b></h3>
	<p>
		<b>PC (eller konsoll) med Skjerm og tilbehør:</b> Vi i TGEX har ikke mulighet
		til å leie eller låne ut PCer eller spillkonsoller.
	</p>
	<p>
		<b>TP-kabel (internettkabel), 6 meter:</b> Noen vil sitte nære switcher,
		andre et stykke unna, men 6 meter holder uansett hvor du sitter.
	</p>
	<p>
		<b>Strømforgrener:</b> Du får kun ett strømuttak og må derfor sørge for nødvendig
		forgrening slik at du får strøm til PC, skjerm, mobillader, osv.
	</p>
	<p>
		<b>Stol:</b> Vi anbefaler alle å ta med sin egen stol. En god kontorstol
		vil gjøre helga betydelig mer behagelig. Har du ikke mulighet til å ta med
		egen stol, velg dette når du registrerer deg så vil vi skaffe deg en. Merk
		at disse ikke er særlig komfortabel over lengre tid. (Anbefales å ta med
		pute eller annet mykt å sitte på)
	</p>
	<p>
		<b>Sovepose og liggeunderlag:</b> Om du har planer om å ikke dra hjem for
		å sove så må du ta med sovepose og liggeunderlag. Vi har en egen sovesal
		hvor folk kan ta med egen madrass eller liggunderlag og sove i.
	</p>
	<br /><br />
	<h2 id="FAQ"><b>OFTE STILTE SPØRSMÅL</b></h2>
	<p>
		<b>Hva er et LAN-party?</b><br />
		<i>
			Et LAN-party er en samling med PCer eller konsoller i samme lokale
			der målet er å være sosial/konkurrere med likesinnede. Størrelsen på
			et LAN-party kan variere fra så få som to til flere tusen.</i
		>
	</p>
	<p>
		<b>Er det aldersgrense på TGEX?</b><br />
		<i>
			Nei, vi har ingen øvre eller nedre aldersgrense, men du må være i
			stand til å ta vare på deg selv.</i
		>
	</p>
	<p>
		<b>Hvor kan jeg parkere bilen min?</b><br />
		<i>
			Rett utenfor TUIL-hallen er det gratis parkering døgnet rundt. Sørg
			for at du ikke står på handicap-plassene om du ikke har særskilt
			tillatelse til å parkere der.</i
		>
	</p>
	<p>
		<b>Må jeg ha ID?</b><br />
		<i>
			Ja! Alle deltakere må kunne identifisere seg selv. Gyldig
			fotoidentifikasjon er bankkort, førerkort og pass.</i
		>
	</p>
	<p>
		<b>Hvor mye plass får jeg?</b><br /><i>
			Hver deltaker får 120x60 cm bordplass.
		</i>
	</p>
	<p>
		<b>Hvordan kommer jeg meg til TUIL-hallen?</b><br /><i>
			Vi anbefaler å få noen til å kjøre deg hvis du skal ha med utstyr,
			men dersom det ikke lar seg gjøre kan du ta Buss rute 20 eller 24
			som kjører til busstoppet <b>Båthavna</b>, som er 900 meter unna
			TUIL Arena. Når du ser TUIL bygget følger du veien til høyre, her
			vil du finne hovedinngangen.
		</i>
	</p>
	<p>
		<b>Hvor finner jeg mat?</b><br /><i>
			TGEX har egen døgnåpen kiosk! Se egen side <a href="/kiosk">her</a>.
			TGEX vil også ha en kafé åpen på Fredag og Lørdag for salg av varme
			matretter.
		</i>
	</p>
	<p>
		<b>Kan jeg dusje og sove under arrangementet?</b><br /><i>
			Vi har et eget området i hallen satt av til soving.
		</i><i>
			Det er garderobe og fellesdusj tilgjengelig for alle deltakerne.</i
		>
	</p>
	<p>
		<b>Hvor mye strøm kan jeg bruke?</b><b><br /></b><i>
			Hver deltaker får et strømuttak, det vil si at alle må ha med egen
			forgrener. Det er ikke tillatt å ta med utstyr som dreger en stor
			mengde strøm. F.eks kjølebokser og små kjøleskap.</i
		>
	</p>
	<p>
		<b>Hva hvis jeg trenger hjelp?</b><br />
		<i>
			Trykk <a href="/about#contact">her</a> for å se hvem du skal kontakte.</i
		>
	</p>
	<p>
		<b>Er billettene refunderbare? </b><br />
		<i> Betalte billetter refunderes ikke.</i>
	</p>
	<p>
		<b>Hvordan kjøper jeg billett?</b><br />
		<i>
			Billett kan du kjøpe på nett og betale med Bankkort ved bruk av
			stripe. Hvis du ikke har mulighet til å betale over nett kan du
			sende oss en melding på facebook så skal vi se på alternativer. Du
			kan også betale billetten din i døra. I døren tar vi i mot kort og
			kontant.
		</i>
	</p>
	<p>
		<b>Hvor henter jeg armbånd?</b><br />
		<i>
			Ønsker du å slippe kø på dagen har du mulighet til å hente armbånd
			på TUIL Esportsenter før LANet. Dato og klokkeslett for når vi deler
			ut armbånd i forkant av arrangementet blir publisert på discord og
			vår facebook-side.</i
		>
	</p>
	<p>
		<b>Hvordan reserverer jeg sitteplass?</b><br />
		<i>Vi åpner for å velge sitteplass når du har betalt din billett.</i>
	</p>
	<p>
		<b>Hvor finner jeg regler for arrangementet? </b><br />
		<i>
			Generelle regler for arrangementet finner du <a href="/rules">her</a
			>
		</i>
	</p>
	<p>
		<b>Kan jeg komme på besøk?</b><br />
		<i>
			På TGEX tillater vi besøk gjennom hele arrangementet. Dette koster
			50,-
		</i>
		<br />
		<i>
			Det er dessverre ikke mulighet til til å ta med egen PC som
			besøkende.
		</i>
	</p>
	<p>
		<b>
			Farsken, armbåndet mitt sletna/sitter for stramt på. Kan jeg få et
			nytt?
		</b><br />
		<i>
			Om armbåndet er satt på for stramt, ikke klipp det av, kom til oss i
			crewet så tar vi det av og setter på et nytt. Om armbåndet ditt er
			ødelagt kan du levere dette inn i bytte mot et nytt. Merk at du ikke
			får beholde det ødelagte. Dette for å hindre at uvedkommende slipper
			inn.
		</i>
	</p>

	<br /><br />
	<h2><b>VED EN NØDSITUASJON</b></h2>
	<p>
		Hvis det skulle oppstå en situasjon der hallen må evakueres vil dette
		bli varslet over vårt PA-anlegg, samt at alle taklys vil bli skrudd på.
		Gå til nærmeste nødutgang i et rolig tempo. La alt utstyr bli liggende,
		da dette vil hindre en ordnet evakuering. Hjelp andre med å komme seg
		ut, og følg alle instruksjoner ifra crewet. Admin og securitycrew vil ha
		gule refleksvester. Hvis du skulle oppdage en brann, utløs nærmeste
		brannalarm, kontakt et crewmedlem og/eller ring 110.
	</p>
	<p>
		Følg instruksjoner fra crewet, brannmanskap og politiet. Hvis
		brannalarmen utløses, skru av all musikk/lyd og følg instrukser gitt
		over PA-anleg. Admin og securitycrew vil overvåke brannalarmsystemet
		døgnet rundt, og reagerer umiddelbart hvis det oppstår avvik fra det
		normale.
	</p>
</div>
<br /><br />
