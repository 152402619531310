import { writable } from 'svelte/store';
import { deleteActivity, getActivities, postActivity, updateActivity, } from '../api/activities';
function createActivities() {
    const { subscribe, set, update } = writable([]);
    const fetchActivities = async () => {
        return set(await getActivities());
    };
    return {
        subscribe,
        fetch: fetchActivities,
        add: async (category) => {
            await postActivity(category);
        },
        change: async (category) => {
            await updateActivity(category);
            fetchActivities();
        },
        delete: async (id) => {
            await deleteActivity(id);
            update((arr) => arr.filter((activities) => activities.id !== id));
        },
    };
}
export const activities = createActivities();
