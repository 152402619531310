<script lang="ts">
	import {activities} from '../stores/activities';
	import {event} from '../stores/event';
	import {news} from '../stores/news';
	import Countdown from '../components/Countdown.svelte';
	import HeroVideo from '../components/HeroVideo.svelte';
	import Slideshow from '../components/Slideshow.svelte';
	import {user} from '../stores/user';
	import {getNextEvent} from '../api/event';

	activities.fetch();
	news.fetch();

	let nextEvent;
	const getEventInfo = async () => {
		nextEvent = await getNextEvent();
	};
	$: nextEvent = nextEvent;
	getEventInfo();
</script>

<HeroVideo />

<div class="content-wrapper">
	<div class="content-30 content-section">
		<div class="content-header">
			<div>
				<h1>TROMSØ GAMING EXPERIENCE</h1>
				<hr />
			</div>
			<div>
				<Countdown event={nextEvent} />
			</div>
		</div>
		<p class="timestamp">9. - 12. mars 2023 | TUIL-Hallen</p>
		{#if !$user || !$user.registrations.length}
			<a href="/registration">
				<button class="action-button cta wiggle">Meld deg på</button>
			</a>
			<a href="/login">
				<button class="action-button neutral cta">Logg inn</button>
			</a>
		{:else if $user && !$user.registrations[0].payments.length}
			<p class="red">Du har enda ikke betalt din billett!</p>
			<a href="/profile">
				<button class="action-button cta wiggle">
					Betal din billett
				</button>
			</a>
		{:else if $user}
			<a href="/profile">
				<button class="action-button cta wiggle">Min profil</button>
			</a>
		{/if}
		<p>
			Tromsø Gaming Experience, er et LAN arrangement for ungdom i alle
			aldre med interesse for spilling, esport, teknologi og alt som hører
			med.
		</p>
		<p>
			Deltakerne får mulighet til å ta med sin egen PC en hel helg i
			TUIL-hallen. Arrangementet er non-profit og baserer all inntekt på
			deltakeravgift og støtte fra lokalt og nasjonalt næringsliv.
		</p>
		<p>
			TGEX har nulltolleranse mot rusmidler, usportslig opptreden, mobbing
			og annen alvorlig uenighet. Du kan lese hele TGEX sin historie her.
		</p>
		<p>
			TGEX er arrangert av flere titalls frivillige spillentusiaster,
			styrt av TGEX SA, et selskap bestående av ildsjeler som brenner for
			gaming og dataspill.
		</p>
	</div>
	<!-- <div class="content-30 content-section">
		<h2>Aktiviteter</h2>
		<hr />
		{#each $activities.slice(0, 3) as activity}
			<a href={`/activity?id=${activity.id}`}>
				<div class="activity">
					<h3>{activity.name}</h3>
					<p>
						{@html activity.content}
					</p>
					<p class="activity-cta">Les mer</p>
					<hr class="hr-slim" />
				</div>
			</a>
		{/each}
		<a href="/activity">Se flere aktiviteter</a>
	</div> -->
	<div class="content-40 content-section">
		<h2>V3 POWERED BY</h2>
		<hr />
		<div class="sponsors-wrapper">
			<a
				class="sponsor large"
				href="https://www.sparebank1.no/nb/nord-norge/om-oss/samfunnsansvar/samfunnsutbytte.html"
				target="_blank"
			>
				<img
					src="assets/sponsors/samfunnsloftet.png"
					alt="Samfunnsløftet"
				/>
				<p>Samfunnsløftet</p>
			</a>
			<a class="sponsor large" href="https://tuil.no" target="_blank">
				<img src="assets/sponsors/tuil.png" alt="TUIL" />
				<p>TUIL Arena</p>
			</a>
			<a class="sponsor" href="https://iteam.no" target="_blank">
				<img src="assets/sponsors/iteam.svg" alt="iteam" />
				<p>iteam Tromsø</p>
			</a>
			<a class="sponsor" href="https://elkjop.no" target="_blank">
				<img src="assets/sponsors/elkjop.png" alt="Elkjøp" />
				<p>Elkjøp</p>
			</a>
			<a class="sponsor" href="https://homenet.no" target="_blank">
				<img src="assets/sponsors/homenet.png" alt="Home_Net" />
				<p>Home_Net</p>
			</a>
			<a
				class="sponsor"
				href="https://www.rema.no/butikker/troms-og-finnmark/tromso/rema-1000-tromso-k1/"
				target="_blank"
			>
				<img src="assets/sponsors/rema.svg" alt="Rema 1000 K1" />
				<p>Rema 1000 K1</p>
			</a>
			<a class="sponsor" href="https://moloen.no" target="_blank">
				<img src="assets/sponsors/moloen.png" alt="Moloen" />
				<p>Moloen</p>
			</a>
			<a class="sponsor" href="https://tvibit.net" target="_blank">
				<img src="assets/sponsors/tvibit.png" alt="Tvibit" />
				<p>Tvibit</p>
			</a>
			<a class="sponsor" href="https://driv.no" target="_blank">
				<img
					src="assets/sponsors/driv.svg"
					alt="Studentsamfunnet Driv"
				/>
				<p>Driv</p>
			</a>
			<a class="sponsor" href="https://explo.no/" target="_blank">
				<img src="assets/sponsors/explo.png" alt="Explo by Mack" />
				<p>Mack Explo</p>
			</a>
			<a class="sponsor" href="https://hermesas.no" target="_blank">
				<img src="assets/sponsors/hermes.png" alt="Hermes" />
				<p>Hermes</p>
			</a>
			<a class="sponsor" href="https://mydland.no" target="_blank">
				<img src="assets/sponsors/mydland.jpg" alt="Mydland" />
				<p>Mydland</p>
			</a>
			<a
				class="sponsor"
				href="https://www.mathallentromso.no/"
				target="_blank"
			>
				<img src="assets/sponsors/mathallen.png" alt="Mathallen" />
				<p>Mathallen</p>
			</a>
			<a class="sponsor" href="https://asko.no" target="_blank">
				<img src="assets/sponsors/asko.svg" alt="Asko" />
				<p>Asko</p>
			</a>
		</div>
		<a href="mailto:brynjar@tgex.no"
			><p><b>Vil din bedrift være med på moroa?</b></p></a
		>
	</div>
	<div class="content-20 content-flex">
		<Slideshow />
		<div class="content-section content-flex-fill">
			<h2>CONNECT WITH US</h2>
			<hr />
			<br />
			<div class="socials">
				<a class="social__link" href="https://facebook.com/tromsogex/"
					><img
						class="social__icon"
						src="assets/some/facebook.svg"
						alt="Facebook logo"
					/>
					FACEBOOK
				</a>
				<a class="social__link" href="https://instagram.com/tromsogex/"
					><img
						class="social__icon"
						src="assets/some/instagram.svg"
						alt="Instagram logo"
					/>INSTAGRAM
				</a>
				<a class="social__link" href="https://discord.gg/jJ6qcvu"
					><img
						class="social__icon"
						src="assets/some/discord.svg"
						alt="Dsicord logo"
					/>DISCORD
				</a>
				<a class="social__link" href="https://www.twitch.tv/tromsogex"
					><img
						class="social__icon"
						src="assets/some/twitch.svg"
						alt="Twitch logo"
					/>TWITCH.TV
				</a>
				<a class="social__link" href="https://www.tiktok.com/@tgex.no"
					><img
						class="social__icon"
						src="assets/some/tiktok.svg"
						alt="Tiktok logo"
					/>TIKTOK
				</a>
			</div>
		</div>
	</div>
</div>
<div class="content-wrapper">
	<div class="content-60 content-section">
		<h2>Nyheter</h2>
		{#each $news as article}
			<a href={`/news/${article.id}`}>
				<div class="activity">
					<h3>{article.title}</h3>
					<p>{article.excerpt}</p>
					<p class="activity-cta">Les mer</p>
					<hr class="hr-slim" />
				</div>
			</a>
		{/each}
		<a href="/news">Se flere nyheter</a>
	</div>
	<div class="content-30 content-section">
		<h2>Administrasjon</h2>
		<div class="admins">
			<div class="admin">
				<img
					class="admin-image"
					src="assets/admin-brynjar.jpg"
					alt="Bilde av Brynjar"
				/>
				<div class="admin-info">
					<h3 class="admin-name">JahuvA</h3>
					<p class="admin-realname">Brynjar Johansen</p>
					<p class="admin-responsibility">Økonomi og Styreleder</p>
					<a href="mailto:brynjar@tgex.no">
						<p class="admin-email">brynjar@tgex.no</p>
					</a>
				</div>
			</div>
			<div class="admin">
				<img
					class="admin-image"
					src="assets/admin-simen.png"
					alt="Bilde av Simen"
				/>
				<div class="admin-info">
					<h3 class="admin-name">NEMISTECH</h3>
					<p class="admin-realname">Simen Fjellstad</p>
					<p class="admin-responsibility">
						Teknisk drift og produksjon
					</p>
					<a href="mailto:simen@tgex.no">
						<p class="admin-email">simen@tgex.no</p>
					</a>
				</div>
			</div>
			<div class="admin">
				<img
					class="admin-image"
					src="assets/admin-tina.png"
					alt="Bilde av Tina"
				/>
				<div class="admin-info">
					<h3 class="admin-name">Tinboo2</h3>
					<p class="admin-realname">Tina C. H. Andersen</p>
					<p class="admin-responsibility">Kiosk, grafisk og PR</p>
					<a href="mailto:tina@tgex.no">
						<p class="admin-email">tina@tgex.no</p>
					</a>
				</div>
			</div>
			<div class="admin">
				<img
					class="admin-image"
					src="assets/admin-daniel.jpg"
					alt="Bilde av daniel"
				/>
				<div class="admin-info">
					<h3 class="admin-name">Barne-TV</h3>
					<p class="admin-realname">Daniel Jensen</p>
					<p class="admin-responsibility">Sikkerhet</p>
					<a href="mailto:daniel@tgex.no">
						<p class="admin-email">daniel@tgex.no</p>
					</a>
				</div>
			</div>
			<div class="admin">
				<img
					class="admin-image"
					src="assets/admin-anders.png"
					alt="Bilde av Anders"
				/>
				<div class="admin-info">
					<h3 class="admin-name">r3amped</h3>
					<p class="admin-realname">Anders Haga</p>
					<p class="admin-responsibility">LAN-ekspert</p>
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	.red {
		color: #e22020;
	}
	.sponsors-wrapper {
		margin-top: 20px;
		display: flex;
		justify-content: space-evenly;
		align-items: baseline;
		flex-wrap: wrap;
	}

	.sponsor {
		display: block;
		flex-basis: 33%;
		padding-right: 10px;
		padding-bottom: 10px;
		text-align: center;
	}

	.sponsor.large {
		flex-basis: 50%;
		padding-right: 10px;
		padding-bottom: 10px;
		vertical-align: bottom;
	}
	.sponsor p {
		font-size: 16px;
		font-weight: bold;
		margin-top: 10px;
		letter-spacing: 1px;
	}
	.sponsor img {
		max-height: 50px;
		max-width: 100%;
		margin: 0 auto;
	}

	.sponsor.large img {
		max-height: 65px;
		max-width: 100%;
	}

	.timestamp {
		font-family: 'Bebas Neue', sans-serif;
		font-size: 19px;
		letter-spacing: 2px;
		font-weight: bold;
	}
	.content-wrapper {
		color: white;
		position: relative;
		display: flex;
		justify-content: center;
		max-width: 1400px;
		margin: 0 auto;
		border-radius: 10px;
		/*margin-bottom: 20px;*/
	}
	.content-60 {
		width: 60%;
		margin: 10px;
	}
	.content-30 {
		margin: 10px;
		color: white;
		position: relative;
		width: 30%;
		overflow: hidden;
		border-radius: 10px;
	}
	.content-20 {
		margin: 10px;
		color: white;
		position: relative;
		width: 20%;
		overflow: hidden;
		border-radius: 10px;
	}
	.content-40 {
		margin: 10px;
		color: white;
		position: relative;
		width: 40%;
		overflow: hidden;
		border-radius: 10px;
	}

	.content-flex {
		display: flex;
		flex-direction: column;
	}
	.content-flex-fill {
		flex: 1;
	}

	.content-section {
		padding: 20px 40px;
		color: white;
		position: relative;
		background-color: #2b2b2b;
		border-radius: 10px;
		border-bottom: solid 4px #ad1b1b;
	}

	.content-header {
		display: flex;
		justify-content: space-between;
	}

	.admin {
		display: flex;
		margin-bottom: 10px;
	}
	.admin-info {
		margin-left: 10px;
	}

	.admin-image {
		width: 100px;
		height: 100px;
		border-radius: 2px;
	}
	.admin-name {
		margin: 0;
	}
	.admin-realname {
		margin: 0;
		font-weight: 600;
	}
	.admin-responsibility {
		margin: 0;
	}
	.admin-email {
		margin: 0;
	}

	.social__link {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: white;
		margin-bottom: 10px;
	}
	.social__icon {
		width: 30px;
		margin-right: 10px;
	}

	.activity {
		color: white;
		margin: 10px 0;
	}

	.activity h3 {
		margin: 0;
		color: #4580e6;
	}
	.activity p {
		margin: 0;
	}
	.activity-cta {
		color: #245dbe;
	}

	@media screen and (max-width: 1100px) {
		.content-wrapper {
			flex-direction: column;
		}
		.content-section {
			padding: 20px;
		}

		.content-60,
		.content-40,
		.content-30,
		.content-20 {
			width: auto;
			margin: 15px;
		}

		.content-header {
			flex-direction: column;
		}

		.sponsor {
			flex-basis: 50%;
		}
	}
</style>
