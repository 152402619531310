<script lang="ts">
	import {resetPassword} from '../api/authentication';
	import {router} from 'tinro';

	let error = '';
	let password1 = '';
	let password2 = '';
	let sent = false;

	const onSubmit = async () => {
		try {
			if (password1 !== password2) {
				error = 'Passwords must match';
				return;
			}

			if (!$router.query.token) {
				throw new Error('Token must be set');
			}

			await resetPassword({
				token: $router.query.token,
				password: password1,
			});
			error = '';
			sent = true;
		} catch (e) {
			error = e.error ? e.error : e;
		}
	};
</script>

<div class="page-content">
	{#if sent}
		<div class="login-page">
			<div class="login-form">
				<h1>Passordet er oppdatert</h1>
				<br />
				<a class="link" href="/login">
					Klikk her for å returnere til innloggingsskjermen
				</a>
				<br />
			</div>
		</div>
	{:else}
		<div class="login-page">
			<div class="login-form">
				<h1>Nullstill Passord</h1>
				<form on:submit|preventDefault={onSubmit}>
					<div>
						<label>
							Passord
							<input type="password" bind:value={password1} />
						</label>
						<label>
							Gjenta Passord
							<input type="password" bind:value={password2} />
						</label>
						<a class="link" href="/login"
							>Kan du allerede passordet?</a
						>
						<br />
						<div class="errortext">{error}</div>
						<br />
						<button type="submit">Reset password</button>
					</div>
				</form>
			</div>
		</div>
	{/if}
</div>
