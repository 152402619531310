<script lang="ts">
	import {onMount} from 'svelte';

	import {getRegistrations} from '../api/registration';
	import {CrewType, tableDefinitions} from '../constants';

	let registrations = [];

	let analysis = {
		genderStats: {male: 0, female: 0, other: 0, malePercentage: 0},
		averageAge: 0,
		ageDistribution: {},
	};
	let analysisPaid = {
		genderStats: {male: 0, female: 0, other: 0, malePercentage: 0},
		averageAge: 0,
		ageDistribution: {},
	};

	onMount(async () => {
		registrations = await getRegistrations();
		registrations = registrations.filter((reg) => !reg.crew);
		analysis = analyzeParticipants(registrations);

		const paid = registrations.filter((reg) => reg.hasPaid);
		analysisPaid = analyzeParticipants(paid);
		console.log('Analysis', analysis);
		console.log('Paid User Analysis', analysisPaid);
	});

	const getAverageAge = (regs) => {
		let ages = regs.map((reg) => getAge(reg.user.birthdate));
		ages = ages.filter((age) => age > 5 && age < 90);
		const total = ages.reduce(
			(partialSum: number, a: number) => partialSum + a,
			0
		);
		return total / ages.length;
	};
	const getAgeDistribution = (regs) => {
		const ages = {};
		regs.forEach((reg) => {
			const age = getAge(reg.user.birthdate);
			if (ages.hasOwnProperty(age)) ages[age]++;
			else ages[age] = 1;
		});
		return ages;
	};
	const analyzeParticipants = (participants) => {
		const output = {
			genderStats: {male: 0, female: 0, other: 0, malePercentage: 0},
			averageAge: 0,
			ageDistribution: {},
		};
		//Ages
		output.averageAge = getAverageAge(participants);
		output.ageDistribution = getAgeDistribution(participants);
		//Genders
		output.genderStats.male = participants.filter(
			(p) => p.user.gender === 'm'
		).length;
		output.genderStats.female = participants.filter(
			(p) => p.user.gender === 'f'
		).length;
		output.genderStats.other = participants.filter(
			(p) => p.user.gender === 'o'
		).length;
		output.genderStats.malePercentage =
			(output.genderStats.male / participants.length) * 100;

		return output;
	};

	const crewTypeMap = {
		[CrewType.Admin]: 'Admin',
		[CrewType.Game]: 'Game',
		[CrewType.Entertainment]: 'Entertainment',
		[CrewType.Tech]: 'Tech',
		[CrewType.Kiosk]: 'Kiosk',
		[CrewType.Security]: 'Security',
	};
	const crewTypeClass = {
		[CrewType.Admin]: 'crew crew-admin',
		[CrewType.Game]: 'crew crew-game',
		[CrewType.Entertainment]: 'crew crew-entertainment',
		[CrewType.Tech]: 'crew crew-tech',
		[CrewType.Kiosk]: 'crew crew-kiosk',
		[CrewType.Security]: 'crew crew-security',
	};

	const genderMap = {
		m: 'Mann',
		f: 'Kvinne',
	};

	const getCrewClass = (participant) => {
		if (participant.crew) {
			return crewTypeClass[participant.crew.type];
		}
		return '';
	};

	function getAge(dateString) {
		let today = new Date();
		let birthDate = new Date(dateString);
		let age = today.getFullYear() - birthDate.getFullYear();
		let m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	const getSeatName = (seat): string => {
		return seat ? tableDefinitions[seat.row].letter + (seat.seat + 1) : '-';
	};

	let showPaidOnly = false;
	let searchTerm = '';
	$: searchTerm = searchTerm;

	const getFiltered = (registrations, showPaidOnly, searchTerm) => {
		let filtered = registrations;
		if (showPaidOnly) filtered = filtered.filter((reg) => reg.hasPaid);
		if (searchTerm && searchTerm !== '')
			filtered = filtered.filter((reg) => {
				const fullName = reg.user.firstName + ' ' + reg.user.lastName;
				if (fullName && fullName.toLowerCase().includes(searchTerm))
					return true;
				if (
					reg.user.gamertag &&
					reg.user.gamertag.toLowerCase().includes(searchTerm)
				)
					return true;

				if (
					reg.seat &&
					getSeatName(reg.seat).toLowerCase().includes(searchTerm)
				)
					return true;

				return false;
			});

		return filtered;
	};
</script>

<div class="page-content">
	<h1>Administrer påmeldte</h1>
	<label>
		<input type="checkbox" bind:checked={showPaidOnly} /> Vis kun betalte brukere
	</label><br />
	<label>
		Søk:
		<input type="text" placeholder="søk" bind:value={searchTerm} />
	</label>
	<br /><br />
	<table>
		<thead>
			<tr>
				<th>#</th>
				<th>RegId (userId)</th>
				<th>Navn</th>
				<th>Gamertag</th>
				<th>Sitteplass</th>
				<th>Alder</th>
				<th>Kjønn</th>
			</tr>
		</thead>
		<tbody>
			{#each getFiltered(registrations, showPaidOnly, searchTerm) as registration, index}
				<tr>
					<td>
						{index + 1}
					</td>
					<td> {registration.id} ({registration.user.id})</td>
					<td class="name" class:paid={registration.hasPaid}>
						<a href={`/admin/participants/${registration.id}`}>
							{registration.user.firstName}
							{registration.user.lastName}
						</a>
					</td>
					<td
						class={getCrewClass(registration)}
						class:paid={registration.hasPaid}
					>
						{#if registration.crew}[{crewTypeMap[
								registration.crew.type
							]}]
						{/if}{registration.user.gamertag}</td
					>
					<td>
						{getSeatName(registration.seat)}
					</td>
					<td>{getAge(registration.user.birthdate)} år</td>

					<td>{genderMap[registration.user.gender] || '---'}</td>
				</tr>
			{/each}
		</tbody>
	</table>
</div>

<style>
	input[type='text'] {
		padding: 5px 10px;
		border-radius: 4px;
		margin-top: 10px;
		font-size: 16px;
	}

	.name {
		color: #eee;
	}
	.paid {
		font-weight: 600;
		color: white;
	}

	.crew-admin {
		color: #6ff838;
	}
	.crew-game {
		color: #2d73f7;
	}
	.crew-entertainment {
		color: #6dddff;
	}
	.crew-kiosk {
		color: #ca00ca;
	}
	.crew-security {
		color: yellow;
	}
	.crew-tech {
		color: #ff3939;
	}
</style>
