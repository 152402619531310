<script lang="ts">
	import {token} from '../stores/token';
	import decode from 'jwt-decode';
	import {router} from 'tinro';
	import {login} from '../api/authentication';

	let email = '';
	let password = '';
	$: error = '';
	let loggedInUser = false;

	if ($token) {
		loggedInUser = decode($token);
	}

	const onSubmit = async () => {
		try {
			const credentials = {email, password};
			const authResponse = await login(credentials);
			$token = authResponse.token;

			router.goto('/profile');
		} catch (e) {
			console.log(e);
			error = e.error;
		}
	};
</script>

<div class="page-content">
	<h1>Logg inn</h1>
	<form on:submit|preventDefault={onSubmit}>
		<label>
			E-post adresse
			<input
				type="email"
				bind:value={email}
				placeholder="ola.nordmann@eksempel.no"
				required
			/>
		</label>
		<label>
			Passord
			<input
				type="password"
				bind:value={password}
				placeholder="Passord"
				required
			/>
		</label>
		<div class="error">{error}</div>
		<a href="/forgot-password">Glemt passordet?</a><br /><br />
		<button type="submit" class="action-button">Logg inn</button>
	</form>
</div>

<style>
	.error {
		color: red;
	}

	label {
		display: block;
	}

	input[type='email'],
	input[type='password'] {
		display: block;
		padding: 10px 20px;
		font-size: 16px;
		border-radius: 5px;
		border: none;
		min-width: 270px;
		margin-top: 2px;
		margin-bottom: 10px;
	}

	.action-button {
		display: inline-block;
		background-color: #67cd5b;
		padding: 9px 16px;
		border: none;
		color: #fff;
		border-radius: 8px;
		white-space: nowrap;
		margin: 3px 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		transition: all 0.2s;
	}

	.action-button:hover {
		cursor: pointer;
		background-color: #66e757;
	}
</style>
