<script lang="ts">
	import type {Credentials, UserData} from '../../global';

	import {token} from '../../stores/token';
	import {login, register} from '../../api/authentication';

	export let registerError = '';

	export let onLogin = () => {};

	const userData: UserData & Credentials = {
		firstName: '',
		lastName: '',
		gamertag: '',
		birthdate: '',
		gender: '',
		tel: '',
		email: '',
		password: '',
	};

	const onRegister = async () => {
		try {
			await register(userData);
			const authResponse = await login(userData);
			$token = authResponse.token;
			onLogin();
		} catch (e) {
			console.log(e);
			registerError = e.error;
		}
	};
</script>

<form on:submit|preventDefault={onRegister}>
	<label
		>Fornavn:
		<input
			type="text"
			bind:value={userData.firstName}
			placeholder="Ola"
			required
		/>
	</label>
	<label
		>Etternavn:
		<input
			type="text"
			bind:value={userData.lastName}
			placeholder="Nordmann"
			required
		/>
	</label>
	<label
		>Gamertag / Kallenavn:
		<input
			type="text"
			name="gamertag"
			id="gamertag"
			bind:value={userData.gamertag}
			placeholder="Gamertag"
			required
		/>
	</label>
	<label
		>Fødselsdato:
		<input type="date" bind:value={userData.birthdate} required />
	</label>
	<label
		>Kjønn:
		<select bind:value={userData.gender} required>
			<option value="">Velg kjønn</option>
			<option value="m">Mann</option>
			<option value="f">Kvinne</option>
			<option value="o">Annet</option>
		</select>
	</label>
	<label
		>Telefonnummer:
		<input
			type="tel"
			bind:value={userData.tel}
			placeholder="+47 12345678"
			required
		/>
	</label>
	<label
		>Epost:
		<input
			type="email"
			bind:value={userData.email}
			placeholder="ola.normann@eksempel.no"
			required
		/>
	</label>
	<label
		>Passord:
		<input
			type="password"
			bind:value={userData.password}
			placeholder="Passord"
			required
		/>
	</label>
	{#if registerError}
		<p class="error">{registerError}</p>
	{/if}
	<button class="action-button neutral" type="submit">Registrer deg</button>
</form>

<style>
	p {
		max-width: 1200px;
		margin-top: 0;
	}

	label {
		display: block;
	}
	.error {
		color: red;
	}

	input[type='text'],
	input[type='tel'],
	input[type='date'],
	input[type='email'],
	input[type='password'],
	select {
		display: block;
		padding: 10px 20px;
		font-size: 16px;
		border-radius: 5px;
		border: none;
		min-width: 270px;
		margin-top: 2px;
		margin-bottom: 10px;
	}

	.action-button {
		display: inline-block;
		background-color: #67cd5b;
		padding: 9px 16px;
		border: none;
		color: #fff;
		border-radius: 8px;
		white-space: nowrap;
		margin: 3px 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		transition: all 0.2s;
	}
	.action-button.neutral {
		background-color: #45a0e8;
	}
	.action-button:hover {
		cursor: pointer;
		background-color: #66e757;
	}
	.action-button.neutral:hover {
		background-color: #45a0e8;
	}
</style>
