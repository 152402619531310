<script lang="ts">
	const images = [
		'assets/slideshow1.jpg',
		'assets/slideshow2.jpg',
		'assets/slideshow3.jpg',
		'assets/slideshow4.jpg',
		'assets/slideshow5.jpg',
		'assets/slideshow6.jpg',
	];

	var slideshowSpeed = 3; //in seconds
	var currentSlide = 0;

	setInterval(function () {
		currentSlide = (currentSlide + 1) % images.length;
	}, slideshowSpeed * 1000);
</script>

<div class="slideshow">
	{#each images as image, index}
		<img alt="" class:active={currentSlide === index} src={image} />
	{/each}
</div>

<style>
	.slideshow {
		border-radius: 10px;
		overflow: hidden;
		margin-bottom: 10px;
	}

	.slideshow img {
		display: none;
	}
	.slideshow img.active {
		display: block;
	}
</style>
