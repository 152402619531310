<script lang="ts">
	import {router} from 'tinro';

	import {token} from '../stores/token';
	$token = null;

	setTimeout(() => {
		router.goto('/');
	}, 3000);
</script>

<div class="page-content">
	<h2>Du er nå logget ut.</h2>
</div>
