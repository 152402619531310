<div class="page-content">
	<h1>Crewportal</h1>
	<h2>Kontaktinfo til admins</h2>

	<table>
		<thead>
			<tr>
				<th>Navn</th>
				<th>Rolle</th>
				<th>Telefon</th>
				<th>Epost</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Brynjar Johansen</td>
				<td class="crew-admin">Admin - Hovedansvar og tech</td>
				<td>91188087</td>
				<td>brynjar@tgex.no</td>
			</tr>
			<tr>
				<td>Tina C. H. Andersen</td>
				<td class="crew-admin">Admin - Kiosk og Markedsføring</td>
				<td>45507093</td>
				<td>tina@tgex.no</td>
			</tr>
			<tr>
				<td>Simen Fjellstad</td>
				<td class="crew-admin">Admin - Gamecrew, Web og streaming</td>
				<td>94880526</td>
				<td>simen@tgex.no</td>
			</tr>
			<tr>
				<td>Daniel Jensen</td>
				<td class="crew-admin">Admin - Security og media</td>
				<td>48024030</td>
				<td>daniel@tgex.no</td>
			</tr>
		</tbody>
	</table>
	<br />
	<h2>Crewadmins</h2>
	<table>
		<thead>
			<tr>
				<th>Navn</th>
				<th>Rolle</th>
				<th>Telefon</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Elise Lorentsen</td>
				<td class="crew-kiosk">Kiosk</td>
				<td>90216604</td>
			</tr>
			<tr>
				<td>Christian Johansen</td>
				<td class="crew-tech">Tech</td>
				<td>97133464</td>
			</tr>
			<tr>
				<td>Morten Marthinsen</td>
				<td class="crew-security">Security</td>
				<td>98020047</td>
			</tr>
			<tr>
				<td>Bjørn R. C. Giswold</td>
				<td class="crew-entertainment">Entertainment</td>
				<td>41460606</td>
			</tr>
			<tr>
				<td>Mats Hansen Andersen</td>
				<td class="crew-game">Game</td>
				<td>92217012</td>
			</tr>
			<tr>
				<td>Siri Andersen</td>
				<td class="crew-game">Game (Operativt)</td>
				<td>95897928</td>
			</tr>
			<tr>
				<td>Vegard Elvevoll Johansen</td>
				<td class="crew-game">Game (Teknisk)</td>
				<td>97155876</td>
			</tr>
		</tbody>
	</table>
</div>

<style>
	.crew-admin {
		color: #6ff838;
	}
	.crew-game {
		color: #2d73f7;
	}
	.crew-entertainment {
		color: #6dddff;
	}
	.crew-kiosk {
		color: #ca00ca;
	}
	.crew-security {
		color: yellow;
	}
	.crew-tech {
		color: #ff3939;
	}
</style>
