<script lang="ts">
	import type {Credentials, UserData} from '../../global';

	import {token} from '../../stores/token';
	import {login} from '../../api/authentication';

	export let loginError = '';

	export let onLogin = () => {};

	const loginCredentials: Credentials = {
		email: '',
		password: '',
	};

	const submitLogin = async () => {
		try {
			const authResponse = await login(loginCredentials);
			$token = authResponse.token;
			onLogin();
		} catch (e) {
			console.log(e);
			loginError = e.error;
		}
	};
</script>

<form on:submit|preventDefault={submitLogin}>
	<label
		>Epost:
		<input
			type="email"
			bind:value={loginCredentials.email}
			placeholder="ola.normann@eksempel.no"
			required
		/>
	</label>

	<label
		>Passord:
		<input
			type="password"
			bind:value={loginCredentials.password}
			placeholder="Passord"
			required
		/>
	</label>
	<p class="error">{loginError}</p>
	<button class="action-button" type="submit">Logg inn</button>
</form>

<style>
	p {
		max-width: 1200px;
		margin-top: 0;
	}

	.error {
		color: red;
	}

	label {
		display: block;
	}

	input[type='email'],
	input[type='password'] {
		display: block;
		padding: 10px 20px;
		font-size: 16px;
		border-radius: 5px;
		border: none;
		min-width: 270px;
		margin-top: 2px;
		margin-bottom: 10px;
	}

	.action-button {
		display: inline-block;
		background-color: #67cd5b;
		padding: 9px 16px;
		border: none;
		color: #fff;
		border-radius: 8px;
		white-space: nowrap;
		margin: 3px 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		transition: all 0.2s;
	}

	.action-button:hover {
		cursor: pointer;
		background-color: #66e757;
	}
</style>
