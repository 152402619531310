import { post, update, get, remove } from './api';
export const postRegistration = async (registrationData) => {
    return post('/participant/register', registrationData);
};
export const getPublicRegistrations = async () => {
    return get('/participants/public');
};
export const getRegistrations = async () => {
    return get('/participants');
};
export const getRegistrationById = async (participantId) => {
    return get(`/participant/${participantId}`);
};
export const updateRegistration = async (participantId, registrationData) => {
    return update(`/participant/${participantId}`, registrationData);
};
export const postManualPayment = async (participantId, reference, amount) => {
    return post(`/participant/${participantId}/payment`, { reference, amount });
};
export const removeManualPayment = async (participantId, paymentId) => {
    return remove(`/participant/${participantId}/payment/${paymentId}`);
};
export const postArmband = async (participantId, reason = null) => {
    return post(`/participant/${participantId}/armband`, { reason });
};
export const postParticipantComment = async (participantId, reason = null) => {
    return post(`/participant/${participantId}/comment`, { reason });
};
export const postParticipantSeat = async (participantId, seat, row) => {
    return post(`/participant/${participantId}/seat`, { seat, row });
};
