import { baseGet, baseUpdate } from './api';
export const getMyUserProfile = async () => {
    return baseGet(`/user/me`);
};
export const updateMyUserProfile = async (user) => {
    return baseUpdate('/user/me', {
        firstName: user.firstName,
        lastName: user.lastName,
        gamertag: user.gamertag,
        email: user.email,
        tel: user.tel,
        gender: user.gender,
        birthdate: user.birthdate,
    });
};
export const updateUserProfile = async (userId, userData) => {
    return baseUpdate(`/user/${userId}`, userData);
};
export const getUserProfile = async (userId) => {
    return baseGet(`/user/${userId}`);
};
