import { writable } from 'svelte/store';
import { deleteArticle, getArticles, postArticle, updateArticle, } from '../api/news';
function createNews() {
    const { subscribe, set, update } = writable([]);
    const fetchNews = async () => {
        return set(await getArticles());
    };
    return {
        subscribe,
        fetch: fetchNews,
        add: async (article) => {
            await postArticle(article);
        },
        change: async (article) => {
            await updateArticle(article);
            fetchNews();
        },
        delete: async (id) => {
            await deleteArticle(id);
            update((arr) => arr.filter((news) => news.id !== id));
        },
    };
}
export const news = createNews();
