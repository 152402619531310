export const CURRENT_EVENT = 1;
export const NEXT_EVENT = 1;
export var Gender;
(function (Gender) {
    Gender["Male"] = "m";
    Gender["Female"] = "f";
    Gender["Other"] = "o";
})(Gender || (Gender = {}));
export var CrewType;
(function (CrewType) {
    CrewType[CrewType["Admin"] = 0] = "Admin";
    CrewType[CrewType["Security"] = 1] = "Security";
    CrewType[CrewType["Kiosk"] = 2] = "Kiosk";
    CrewType[CrewType["Tech"] = 3] = "Tech";
    CrewType[CrewType["Game"] = 4] = "Game";
    CrewType[CrewType["Entertainment"] = 5] = "Entertainment";
})(CrewType || (CrewType = {}));
export var TicketType;
(function (TicketType) {
    TicketType[TicketType["Crew"] = 0] = "Crew";
    TicketType[TicketType["Standard"] = 1] = "Standard";
    TicketType[TicketType["StandardEarlyBird"] = 2] = "StandardEarlyBird";
})(TicketType || (TicketType = {}));
export const tableDefinitions = {
    0: { name: 'Bord A', letter: 'A' },
    1: { name: 'Bord B', letter: 'B' },
    2: { name: 'Bord C', letter: 'C' },
    3: { name: 'Bord D', letter: 'D' },
    4: { name: 'Bord E', letter: 'E' },
    5: { name: 'Bord F', letter: 'F' },
    6: { name: 'Bord G', letter: 'G', rules: ['16+'] },
    7: { name: 'Bord H', letter: 'H' },
    8: { name: 'Bord I', letter: 'I' },
    9: { name: 'Bord J', letter: 'J' },
    10: { name: 'Bord K', letter: 'K' },
    11: { name: 'Bord L', letter: 'L' },
    12: { name: 'Bord M', letter: 'M' },
    13: { name: 'Bord N', letter: 'N', rules: ['16+'] },
    14: { name: 'Bord O', letter: 'O' },
    15: { name: 'Bord P', letter: 'P' },
    100: { name: 'Crew A', letter: '', rules: ['crew'] },
    101: { name: 'Crew B', letter: '', rules: ['crew'] },
    102: { name: 'Crew C', letter: '', rules: ['crew'] },
    103: { name: 'FOH', letter: '', rules: ['production'], tableSize: 3 },
};
