import { remove, get, post, update } from './api';
export const deleteArticle = async (articleId) => {
    return remove(`/news/${articleId}`);
};
export const getArticles = async () => {
    return get(`/news`);
};
export const postArticle = async (article) => {
    return post('/news', {
        title: article.title,
        excerpt: article.excerpt,
        content: article.content,
    });
};
export const updateArticle = async (article) => {
    return update('/news/' + article.id, {
        title: article.title,
        excerpt: article.excerpt,
        content: article.content,
    });
};
