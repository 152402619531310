<script lang="ts">
	import {onMount} from 'svelte';

	import {getCrew} from '../api/crew';
	import {CrewType} from '../constants';

	let crew = [];

	onMount(async () => {
		crew = await getCrew();
		crew = crew.sort((c1, c2) => c1.type - c2.type);
	});

	const crewTypeClass = {
		[CrewType.Admin]: 'crew crew-admin',
		[CrewType.Game]: 'crew crew-game',
		[CrewType.Entertainment]: 'crew crew-entertainment',
		[CrewType.Tech]: 'crew crew-tech',
		[CrewType.Kiosk]: 'crew crew-kiosk',
		[CrewType.Security]: 'crew crew-security',
	};

	const getCrewClass = (type) => {
		return crewTypeClass[type];
	};
</script>

<div class="page-content">
	<h1>Administrer Crew</h1>
	<table>
		<thead>
			<tr>
				<th>Ids</th>
				<th>Navn</th>
				<th>Gamertag</th>
				<th>Epost</th>
				<th>Tel</th>
				<th>Klesstørrelse</th>
				<th>Førerkort?</th>
			</tr>
		</thead>
		<tbody>
			{#each crew as crewMember}
				<tr>
					<td>{crewMember.registration.id} ({crewMember.user.id})</td>
					<td class={getCrewClass(crewMember.type)}>
						{crewMember.user.firstName}
						{crewMember.user.lastName}
					</td>
					<td>
						<a
							href={`/admin/participants/${crewMember.registration.id}`}
						>
							{crewMember.user.gamertag}
						</a>
					</td>
					<td>{crewMember.user.email}</td>
					<td>{crewMember.user.tel}</td>
					<td class="allcaps">
						{crewMember.applications
							.map((app) => app.clothingSize)
							.join(',')}
					</td>
					<td>{crewMember.hasDriversLicense ? 'ja' : '---'}</td>
				</tr>
			{/each}
		</tbody>
	</table>
</div>

<style>
	.allcaps {
		text-transform: uppercase;
	}
	.crew-admin {
		color: #6ff838;
	}
	.crew-game {
		color: #2d73f7;
	}
	.crew-entertainment {
		color: #6dddff;
	}
	.crew-kiosk {
		color: #ca00ca;
	}
	.crew-security {
		color: yellow;
	}
	.crew-tech {
		color: #ff3939;
	}
</style>
