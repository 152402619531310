<script lang="ts">
	import {
		getRegistrationById,
		updateRegistration,
		postArmband,
		postParticipantComment,
		postManualPayment,
		removeManualPayment,
	} from '../api/registration';
	import {updateUserProfile} from '../api/user';
	import {CrewType, tableDefinitions} from '../constants';

	import type {CrewApplication, RegistrationData, UserData} from '../global';

	import {token} from '../stores/token';

	export let participantId: number;

	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}

	let birthdate;

	const getRegistration = async () => {
		const regById = await getRegistrationById(participantId);
		registration = regById;
		birthdate = formatDate(registration.user.birthdate);
	};

	if ($token) {
		getRegistration();
	}

	let isEditingUser = false;
	let isEditingRegistration = false;
	$: registration = <UserProfile>registration;

	const genderMap = {
		m: 'Gutt / Mann',
		f: 'Jente / Kvinne',
		o: 'Annet',
	};

	const ticketTypeMap = {
		0: 'Crew',
		1: 'Standard',
	};

	const crewTypeMap = {
		[CrewType.Game]: 'Game',
		[CrewType.Entertainment]: 'Entertainment',
		[CrewType.Tech]: 'Tech',
		[CrewType.Kiosk]: 'Kiosk',
		[CrewType.Security]: 'Security',
	};

	const consoleMap = {
		pc: 'PC',
		ps5: 'Playstation 5',
		ps4: 'Playstation 4',
		xbs: 'Xbox Series X/S',
		xbone: 'Xbox One',
		switch: 'Nintendo Switch',
		other: 'Annet',
	};

	const getHumanPaymentMethod = (method) => {
		if (method === 'manual') return 'manuell registrering';

		return method;
	};

	const editProfile = () => (isEditingUser = true);
	const onEditProfile = async () => {
		registration.user.birthdate = birthdate;
		await updateUserProfile(registration.user.id, registration.user);
		isEditingUser = false;
	};

	const editRegistration = () => {
		registration.consoles.split(',').forEach((console) => {
			checkedConsoles[console] = true;
		});
		isEditingRegistration = true;
	};
	const onEditRegistration = async (registrationId: number) => {
		registration.consoles = getCheckedConsoles();
		await updateRegistration(registrationId, registration);
		isEditingRegistration = false;
	};

	const onAddPayment = async (amount, reference = null) => {
		const payment = await postManualPayment(
			participantId,
			reference,
			amount
		);
		getRegistration();
	};

	const getCheckedConsoles = () => {
		let consoles = [];
		Object.entries(checkedConsoles).forEach(([key, value]) => {
			if (value) consoles.push(key);
		});

		return consoles.join(',');
	};
	const consoles = {
		pc: 'PC',
		ps5: 'Playstation 5',
		ps4: 'Playstation 4',
		xbs: 'Xbox Series X/S',
		xbone: 'Xbox One',
		switch: 'Nintendo Switch',
		other: 'Annet',
	};
	const checkedConsoles = {
		pc: false,
		ps5: false,
		ps4: false,
		xbs: false,
		xbone: false,
		switch: false,
		other: false,
	};

	let paymentExpanded = false;
	const expandPayment = () => (paymentExpanded = !paymentExpanded);

	let armbandExpanded = false;
	const expandArmband = () => (armbandExpanded = !armbandExpanded);

	let seatingExpanded = false;
	const expandSeating = () => (seatingExpanded = !seatingExpanded);

	interface UserProfile extends UserData {
		registrations: RegistrationData[];
		crewApplications?: CrewApplication[];
	}
</script>

<div class="page-content">
	{#if registration}
		<h1>
			{registration.user.firstName}
			{registration.user.lastName} sin Brukerprofil
		</h1>
		{#if !isEditingUser}
			<p class="profile__info">
				<span class="profile__label">Navn:</span>
				{registration.user.firstName}
				{registration.user.lastName}
			</p>
			<p class="profile__info">
				<span class="profile__label">Kallenavn:</span>
				{registration.user.gamertag}
			</p>
			<p class="profile__info">
				<span class="profile__label">Epost:</span>
				{registration.user.email}
			</p>
			<p class="profile__info">
				<span class="profile__label">Telefon:</span>
				{registration.user.tel}
			</p>
			<p class="profile__info">
				<span class="profile__label">Fødselsdato:</span>
				{new Date(registration.user.birthdate).toLocaleDateString(
					'nob'
				)}
			</p>
			<p class="profile__info">
				<span class="profile__label">Kjønn:</span>
				{genderMap[registration.user.gender]}
			</p>
			<button on:click={editProfile} class="action-button">
				Rediger profil
			</button>
			<br />
		{:else}
			<form on:submit|preventDefault={onEditProfile}>
				<label>
					Fornavn:
					<input
						type="text"
						bind:value={registration.user.firstName}
						placeholder="Ola"
					/>
				</label>
				<label>
					Etternavn:
					<input
						type="text"
						bind:value={registration.user.lastName}
						placeholder="Nordmann"
					/>
				</label>
				<label>
					Gamertag / Kallenavn:
					<input
						type="text"
						bind:value={registration.user.gamertag}
						placeholder="Gamertag"
					/>
				</label>
				<label>
					Fødselsdato:
					<input type="date" bind:value={birthdate} />
				</label>
				<label>
					Kjønn:
					<select bind:value={registration.user.gender}>
						<option value="">Velg kjønn</option>
						<option value="m">Mann / Gutt</option>
						<option value="f">Kvinne / Jente</option>
						<option value="o">Annet</option>
					</select>
				</label>
				<label>
					Telefonnummer:
					<input
						type="tel"
						bind:value={registration.user.tel}
						placeholder="+47 12345678"
					/>
				</label>
				<label>
					Kontakt Epost:
					<input
						type="email"
						bind:value={registration.user.email}
						placeholder="ola.normann@eksempel.no"
					/>
				</label>
				<button type="submit" class="action-button">
					Rediger profil
				</button>
			</form>
		{/if}
		<h2>Påmeldingsinfo</h2>
		{#if !isEditingRegistration}
			<p class="profile__info">
				<span class="profile__label">Billettype:</span>
				{ticketTypeMap[registration.ticketType]}
			</p>
			<p class="profile__info">
				<span class="profile__label">Deltar med:</span>
			</p>
			<ul class="profile__info">
				{#each registration.consoles.split(',') as console}
					<li>{consoleMap[console]}</li>
				{/each}
			</ul>
			<p class="profile__info">
				{registration.bringsOwnChair
					? 'Tar med egen stol'
					: 'Tar IKKE med egen stol'}
			</p>
			<p class="profile__info">
				{registration.public
					? 'Deltakelse er offentlig'
					: 'Deltakelse er anonym og vises ikke for andre på nett'}
			</p>
			<button on:click={editRegistration} class="action-button">
				Rediger påmelding
			</button>
		{:else}
			<form
				on:submit|preventDefault={() =>
					onEditRegistration(registration.id)}
			>
				<label>
					<input
						type="checkbox"
						bind:checked={registration.bringsOwnChair}
					/>
					Tar med egen stol
				</label>
				<label>
					<input type="checkbox" bind:checked={registration.public} />
					Vis gamertag/kallenavn offentlig i deltakerlisten
				</label>
				<br />
				<div class="console-container">
					<p class="field-name">Deltar med:</p>
					<ul class="ks-cboxtags">
						{#each Object.entries(consoles) as [key, value], index}
							<li>
								<input
									type="checkbox"
									id={`console-${key}`}
									value={key}
									bind:checked={checkedConsoles[key]}
								/><label for={`console-${key}`}>{value}</label>
							</li>
						{/each}
					</ul>
				</div>
				<button type="submit" class="action-button">
					Rediger påmelding
				</button>
				<br />
			</form>
		{/if}
		<br /><br />
		<div
			class="profile-section"
			class:green={registration.payments?.length}
			class:red={!registration.payments?.length}
		>
			<div class="profile-section__collapsed">
				<div class="profile-section__left">
					<h3 class="profile-section__title">Betaling</h3>
					<p class="profile-section__info">
						{#if !registration.payments?.length}
							Har ikke betalt for deltakelsen enda.
							{registration.payments}
						{:else}
							{#each registration.payments as payment}
								Betaling:
								{new Date(payment.paid_at).toLocaleDateString(
									'nob'
								)} kl.
								{new Date(payment.paid_at).toLocaleTimeString(
									'nob'
								)} via
								<b>{getHumanPaymentMethod(payment.method)}</b>

								<br />
							{/each}
						{/if}
					</p>
				</div>
				<button class="expandButton" on:click={expandPayment}>
					<div class="profile-section__right">
						<p class="profile-section__plus">+</p>
						<p class="profile-section__plus-label">
							Administrer Betalinger
						</p>
					</div>
				</button>
			</div>
			{#if paymentExpanded}
				<div class="profile-section__expanded">
					<table>
						<thead>
							<tr>
								<th>Dato</th>
								<th>Metode</th>
								<th>Beløp</th>
								<th>Referanse</th>
								<th>Handlinger</th>
							</tr>
						</thead>
						<tbody>
							{#each registration.payments as payment}
								<tr>
									<td>
										{new Date(
											payment.paid_at
										).toLocaleDateString('nob')} kl.
										{new Date(
											payment.paid_at
										).toLocaleTimeString('nob')}
									</td>
									<td>
										<b
											>{getHumanPaymentMethod(
												payment.method
											)}</b
										>
										{#if payment.user}
											<br />
											av {payment.user?.firstName}
											{payment.user?.lastName}
										{/if}
									</td>
									<td>
										{payment.amount / 100}NOK
									</td>
									<td>
										<span class="small"
											>{payment.reference || ''}</span
										>
									</td>
									<td>
										{#if payment.method === 'manual'}
											<button
												on:click={async () => {
													await removeManualPayment(
														registration.id,
														payment.id
													);
													getRegistration();
												}}
												class="linkbutton"
											>
												Fjern
											</button>
										{/if}
									</td>
								</tr>
							{/each}
						</tbody>
					</table>

					<h3>Registrer manuell innbetaling</h3>
					<button
						class="payment-button"
						on:click={() => onAddPayment(60000, 'kontant')}
					>
						Registrer betaling med kontant
					</button>
				</div>
			{/if}
		</div>
		<br />
		<div
			class="profile-section"
			class:green={registration.armbands?.length}
			class:red={!registration.armbands?.length}
		>
			<div class="profile-section__collapsed">
				<div class="profile-section__left">
					<h3 class="profile-section__title">Armbånd</h3>
					<p class="profile-section__info">
						{#if !registration.armbands?.length}
							Har ikke fått armbånd enda
						{:else}
							{#each registration.armbands as armband}
								Armbånd utstedet
								{new Date(
									armband.created_at
								).toLocaleDateString('nob')}
							{/each}
						{/if}
					</p>
				</div>
				<button
					class="expandButton"
					on:click={async () => {
						if (
							!confirm(
								'Are you sure you want to issue armband to this user?'
							)
						)
							return;
						await postArmband(registration.id);
						getRegistration();
					}}
				>
					<div class="profile-section__right">
						<p class="profile-section__plus">+</p>
						<p class="profile-section__plus-label">
							Registrer armband
						</p>
					</div>
				</button>
			</div>
		</div>
		<br />
		<div
			class="profile-section"
			class:green={registration.seat}
			class:red={!registration.seat}
		>
			<div class="profile-section__collapsed">
				<div class="profile-section__left">
					<h3 class="profile-section__title">Setevalg</h3>
					<p class="profile-section__info">
						{#if !registration.seat}
							Har ikke valgt sete (Kun deltakere med betalte
							billetter kan velge sete)
						{:else}
							Valgt sete: <b
								>{tableDefinitions[registration.seat.row].name} Sete
								{registration.seat.seat + 1}</b
							>
						{/if}
					</p>
				</div>
				<!-- <button class="expandButton" on:click={expandSeating}>
					<div class="profile-section__right">
						<p class="profile-section__plus">+</p>
						<p class="profile-section__plus-label">Bytt setevalg</p>
					</div>
				</button> -->
			</div>
		</div>
		{#if registration.crewApplications && registration.crewApplications.length}
			<h2>Mine crewsøknader</h2>
			{#each registration.crewApplications as application}
				<div
					class:green={application.isReviewed &&
						application.isApproved}
					class:red={application.isReviewed &&
						!application.isApproved}
					class="profile-section"
				>
					<div class="profile-section__left">
						<h3 class="profile-section__title">
							Søknad som: 1. {crewTypeMap[application.wantedCrew]}
							{#if application.wantedCrew2}2. {crewTypeMap[
									application.wantedCrew2
								]}{/if}
							{#if application.wantedCrew3}3. {crewTypeMap[
									application.wantedCrew3
								]}{/if}
						</h3>
						<div class="profile-section__info">
							{#if !application.isReviewed}
								<p>Søknaden har enda ikke blitt vurdert</p>
							{:else if application.isApproved}
								<p>
									Søknaden din er godkjent, velkommen til
									crew!
								</p>
							{:else}
								<p>Søknaden din ble avvist.</p>
								<p>
									<b>Begrunnelse:</b>
									{application.reviewReason}
								</p>
							{/if}
						</div>
					</div>
				</div>
				<br />
			{/each}
		{/if}
	{/if}
</div>

<style>
	p {
		max-width: 1200px;
		margin-top: 0;
	}

	label {
		display: block;
	}
	.error {
		color: #ff3333;
	}

	.small {
		font-size: 0.6em;
	}
	.expandButton {
		background: none;
		border: none;
		color: white;
	}

	input[type='text'],
	input[type='tel'],
	input[type='date'],
	input[type='email'],
	input[type='password'],
	select {
		display: block;
		padding: 10px 20px;
		font-size: 16px;
		border-radius: 5px;
		border: none;
		min-width: 270px;
		margin-top: 2px;
		margin-bottom: 10px;
	}

	.profile__info {
		margin: 5px 0;
	}
	.profile__label {
		font-weight: bold;
	}

	.profile-section {
		background: #999;
		padding: 20px;
		align-items: center;
		max-width: 800px;
		border-radius: 5px;
		flex-wrap: wrap;
	}
	.profile-section__collapsed {
		display: flex;
		align-items: center;
		max-width: 800px;
		border-radius: 5px;
		flex-wrap: wrap;
	}
	.profile-section.red {
		background: #e03939;
	}
	.profile-section.green {
		background: #43b122;
	}

	.profile-section__title {
		font-size: 25px;
		margin: 0;
	}
	.profile-section__info {
		margin: 0;
	}
	.profile-section__left {
		flex: 1;
	}
	.profile-section__right {
		text-align: center;
		margin-right: 10px;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}
	.profile-section__left {
		flex: 1;
		flex-basis: 1;
	}
	.profile-section__right:hover {
		cursor: pointer;
		color: #f0f0f0;
	}
	.profile-section__right:active {
		transform: translateY(1px);
	}
	.profile-section__plus {
		font-size: 30px;
		margin: 0;
		line-height: 1;
	}
	.profile-section__plus-label {
		margin: 0;
	}

	.profile-section__expanded {
		margin-top: 20px;
	}

	.ticketselector {
		display: flex;
	}

	.tickettype {
		padding: 10px;
		background-color: #999;
		margin-right: 10px;
		border-radius: 5px;
		min-width: 200px;
	}
	.tickettype.selected {
		background: #43b122;
	}
	.tickettype.outofstock {
		opacity: 0.6;
	}

	.tickettype__price {
		font-size: 18px;
		font-weight: bold;
	}
	.tickettype p,
	.tickettype h4 {
		margin: 0;
	}

	.payment-button {
		padding: 10px 20px;
		border: none;
		background-color: royalblue;
		color: white;
		border-radius: 5px;
		font-size: 18px;
	}
	.payment-button:hover {
		background-color: rgb(101, 138, 250);
		cursor: pointer;
	}
	.payment-button:active {
		transform: translateY(1px);
	}

	input[type='checkbox'] {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 10px;
	}

	.console-container {
		max-width: 550px;
	}
	ul.ks-cboxtags {
		list-style: none;
		padding-left: 0;
	}
	ul.ks-cboxtags li {
		display: inline;
	}
	ul.ks-cboxtags li label {
		display: inline-block;
		background-color: rgba(255, 255, 255, 0.9);
		border: 2px solid rgba(139, 139, 139, 0.3);
		color: #adadad;
		border-radius: 8px;
		white-space: nowrap;
		margin: 3px 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		transition: all 0.2s;
	}

	ul.ks-cboxtags li label {
		padding: 8px 12px;
		cursor: pointer;
	}

	ul.ks-cboxtags li label::before {
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		font-size: 12px;
		padding: 2px 6px 2px 2px;
		content: '\f067';
		transition: transform 0.3s ease-in-out;
	}

	ul.ks-cboxtags li input[type='checkbox']:checked + label::before {
		content: '\f00c';
		transform: rotate(-360deg);
		transition: transform 0.3s ease-in-out;
	}

	ul.ks-cboxtags li input[type='checkbox']:checked + label {
		border: 2px solid #f81b1b;
		background-color: #d41212;
		color: #fff;
		transition: all 0.2s;
	}

	ul.ks-cboxtags li input[type='checkbox'] {
		display: absolute;
	}
	ul.ks-cboxtags li input[type='checkbox'] {
		position: absolute;
		opacity: 0;
	}
</style>
