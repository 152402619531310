<script lang="ts">
	import {onMount} from 'svelte';

	import {getPublicRegistrations} from '../api/registration';
	import {CrewType} from '../constants';

	let participants = [];
	let crew = [];
	let unfilteredParticipants = [];

	onMount(async () => {
		unfilteredParticipants = await getPublicRegistrations();

		crew = unfilteredParticipants;
		crew = crew.filter((person) => person.crew !== null);
		crew = crew.sort(
			(person, person2) => person.crew.type - person2.crew.type
		);
	});

	const genderMap = {
		m: 'Mann',
		f: 'Kvinne',
	};

	// const consoleMap = {
	// 	pc: 'PC',
	// 	ps5: 'Playstation 5',
	// 	ps4: 'Playstation 4',
	// 	xbs: 'Xbox Series X/S',
	// 	xbone: 'Xbox One',
	// 	switch: 'Nintendo Switch',
	// 	other: 'Annet',
	// };

	const crewTypeMap = {
		[CrewType.Admin]: 'Admin',
		[CrewType.Game]: 'Game',
		[CrewType.Entertainment]: 'Entertainment',
		[CrewType.Tech]: 'Tech',
		[CrewType.Kiosk]: 'Kiosk',
		[CrewType.Security]: 'Security',
	};
	const crewTypeClass = {
		[CrewType.Admin]: 'crew crew-admin',
		[CrewType.Game]: 'crew crew-game',
		[CrewType.Entertainment]: 'crew crew-entertainment',
		[CrewType.Tech]: 'crew crew-tech',
		[CrewType.Kiosk]: 'crew crew-kiosk',
		[CrewType.Security]: 'crew crew-security',
	};

	const getCrewName = (crew) => {
		const baseCrew = crewTypeMap[crew.type];
		let name = baseCrew;
		if (crew.isCrewAdmin) {
			name += ' Admin';
		}
		return `[${name}]`;
	};

	const getCrewClass = (participant) => {
		if (participant.crew) {
			return crewTypeClass[participant.crew.type];
		}
		return '';
	};

	$: getCrewCount = () => {
		return unfilteredParticipants.filter((p) => p.crew).length;
	};
</script>

<div class="page-content">
	<h1>Crew</h1>
	<p>
		Det er hittil {getCrewCount()} personer i crewet til TGEX påmeldt til TGEX
		V3.<br />Har du også lyst å være med?
		<a href="/join-crew">Søk Crew</a>
		<br />
	</p>
	<table>
		<thead>
			<tr>
				<th>#</th>
				<th>Gamertag/Kallenavn</th>
				<th>Kjønn</th>
				<!-- <th>Deltar med</th> -->
			</tr>
		</thead>
		<tbody>
			{#each crew as participant, index}
				<tr>
					<td>{index + 1}</td>
					<td
						class:confirmed={false}
						class:paid={participant.crew.isCrewAdmin ||
							participant.crew.type === 0}
						class={'name ' + getCrewClass(participant)}
					>
						{getCrewName(participant.crew)}
						{participant.user.gamertag}
					</td>
					<td>{genderMap[participant.user.gender] || '---'}</td>
					<!-- <td>
						{participant.consoles
							.toLowerCase()
							.split(',')
							.map((con) => consoleMap[con])
							.join(', ')}
					</td> -->
				</tr>
			{/each}
		</tbody>
	</table>
</div>

<style>
	th {
		font-size: 1.2em;
	}
	.confirmed {
		font-weight: 600;
	}

	.name {
		color: #eee;
	}

	.paid {
		font-weight: bold;
		color: white;
	}

	.not-selected {
		color: #aaa;
	}

	.crew-admin {
		color: #6ff838;
	}
	.crew-game {
		color: #2d73f7;
	}
	.crew-entertainment {
		color: #6dddff;
	}
	.crew-kiosk {
		color: #ca00ca;
	}
	.crew-security {
		color: yellow;
	}
	.crew-tech {
		color: #ff3939;
	}
</style>
