<script lang="ts">
	import {updateRegistration} from '../api/registration';

	import {updateMyUserProfile} from '../api/user';
	import {CrewType, tableDefinitions} from '../constants';
	import {basePost} from '../api/api';

	import type {
		CrewApplication,
		CrewApplicationData,
		RegistrationData,
		UserData,
	} from '../global';

	import {token} from '../stores/token';
	import {user} from '../stores/user';

	const urlParams = new URLSearchParams(window.location.search);
	const paymentsuccess = urlParams.has('paymentsuccess');
	const paymentcancelled = urlParams.has('paymentcancelled');

	function formatDate(date) {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}

	let birthdate;

	if ($token) {
		user.fetch().then(() => {
			birthdate = formatDate($user.birthdate);
		});
	}

	let isEditingUser = false;
	let isEditingRegistration = false;
	$: userProfile = <UserProfile>$user;
	const genderMap = {
		m: 'Gutt / Mann',
		f: 'Jente / Kvinne',
		o: 'Annet',
	};

	const ticketTypeMap = {
		0: 'Crew',
		1: 'Standard',
		2: 'Standard (Early bird)',
	};

	const crewTypeMap = {
		[CrewType.Game]: 'Game',
		[CrewType.Entertainment]: 'Entertainment',
		[CrewType.Tech]: 'Tech',
		[CrewType.Kiosk]: 'Kiosk',
		[CrewType.Security]: 'Security',
	};

	const consoleMap = {
		pc: 'PC',
		ps5: 'Playstation 5',
		ps4: 'Playstation 4',
		xbs: 'Xbox Series X/S',
		xbone: 'Xbox One',
		switch: 'Nintendo Switch',
		other: 'Annet',
	};

	const getHumanPaymentMethod = (method) => {
		if (method === 'manual') return 'manuell registrering';

		return method;
	};

	const editProfile = () => (isEditingUser = true);
	const onEditProfile = async () => {
		userProfile.birthdate = birthdate;
		await updateMyUserProfile(userProfile);
		isEditingUser = false;
	};

	const editRegistration = () => {
		const registration = userProfile.registrations[0]; //TODO - Make this not reliant on only 1 registration being applicable
		registration.consoles.split(',').forEach((console) => {
			checkedConsoles[console] = true;
		});
		isEditingRegistration = true;
	};
	const onEditRegistration = async (registrationId: number) => {
		const registration = userProfile.registrations[0]; //TODO - Make this not reliant on only 1 registration being applicable
		registration.consoles = getCheckedConsoles();
		await updateRegistration(registrationId, registration);
		isEditingRegistration = false;
	};

	const getCheckedConsoles = () => {
		let consoles = [];
		Object.entries(checkedConsoles).forEach(([key, value]) => {
			if (value) consoles.push(key);
		});

		return consoles.join(',');
	};
	const consoles = {
		pc: 'PC',
		ps5: 'Playstation 5',
		ps4: 'Playstation 4',
		xbs: 'Xbox Series X/S',
		xbone: 'Xbox One',
		switch: 'Nintendo Switch',
		other: 'Annet',
	};
	const checkedConsoles = {
		pc: false,
		ps5: false,
		ps4: false,
		xbs: false,
		xbone: false,
		switch: false,
		other: false,
	};

	let paymentExpanded = false;
	const expandPayment = () => (paymentExpanded = !paymentExpanded);

	const purchaseTicket = async (registrationId) => {
		const response = await basePost('/create-checkout-session', {
			registrationId,
		});
		location.href = response.paymentUrl;
	};

	interface UserProfile extends UserData {
		registrations: RegistrationData[];
		crewApplications?: CrewApplication[];
	}

	const hasPaid = (reg: RegistrationData) => Boolean(reg.payments.length);
	const ticketingOpen = (reg: RegistrationData) =>
		Boolean(reg.event.ticketSalesOpen);
</script>

<div class="page-content">
	{#if paymentsuccess}
		<div class="payment-notice">
			Ditt billettkjøp er fullført. Du kan nå velge din sitteplass ved å
			gå til <a href="/seatreg">setevalg</a> seksjonen under
		</div>
	{:else if paymentcancelled}
		<div class="payment-notice">
			Ditt billettkjøp ble <b>ikke</b> vellykket! du kan prøve igjen,
			eller <a href="/about">ta kontakt</a> med oss dersom du har problemer
		</div>
	{/if}
	{#if userProfile}
		<h1>Min Brukerprofil</h1>
		{#if !isEditingUser}
			<p class="profile__info">
				<span class="profile__label">Navn:</span>
				{userProfile.firstName}
				{userProfile.lastName}
			</p>
			<p class="profile__info">
				<span class="profile__label">Kallenavn:</span>
				{userProfile.gamertag}
			</p>
			<p class="profile__info">
				<span class="profile__label">Epost:</span>
				{userProfile.email}
			</p>
			<p class="profile__info">
				<span class="profile__label">Telefon:</span>
				{userProfile.tel}
			</p>
			<p class="profile__info">
				<span class="profile__label">Fødselsdato:</span>
				{new Date(userProfile.birthdate).toLocaleDateString('nob')}
			</p>
			<p class="profile__info">
				<span class="profile__label">Kjønn:</span>
				{genderMap[userProfile.gender]}
			</p>
			<button on:click={editProfile} class="action-button">
				Rediger profil
			</button>
			<br />
		{:else}
			<form on:submit|preventDefault={onEditProfile}>
				<label>
					Fornavn:
					<input
						type="text"
						bind:value={userProfile.firstName}
						placeholder="Ola"
					/>
				</label>
				<label>
					Etternavn:
					<input
						type="text"
						bind:value={userProfile.lastName}
						placeholder="Nordmann"
					/>
				</label>
				<label>
					Gamertag / Kallenavn:
					<input
						type="text"
						bind:value={userProfile.gamertag}
						placeholder="Gamertag"
					/>
				</label>
				<label>
					Fødselsdato:
					<input type="date" bind:value={birthdate} />
				</label>
				<label>
					Kjønn:
					<select bind:value={userProfile.gender}>
						<option value="">Velg kjønn</option>
						<option value="m">Mann / Gutt</option>
						<option value="f">Kvinne / Jente</option>
						<option value="o">Annet</option>
					</select>
				</label>
				<label>
					Telefonnummer:
					<input
						type="tel"
						bind:value={userProfile.tel}
						placeholder="+47 12345678"
					/>
				</label>
				<label>
					Kontakt Epost:
					<input
						type="email"
						bind:value={userProfile.email}
						placeholder="ola.normann@eksempel.no"
					/>
				</label>
				<button type="submit" class="action-button">
					Rediger profil
				</button>
			</form>
		{/if}
		{#if !userProfile.registrations.length}
			<h2>Påmeldingsinfo</h2>
			<p class="error">Du er ikke påmeldt TGEX.</p>
			<p>
				Om du ønsker å melde deg på kan du gå til
				<a href="/registration">påmelding</a>.
			</p>
			{#if userProfile.crewApplications && userProfile.crewApplications.length}
				<p>
					Om din crewsøknad godkjennes og du ikke er påmeldt, vil det
					automatisk opprettes en påmelding uten at du trenger å gjøre
					noe
				</p>
			{/if}
		{/if}
		{#each userProfile.registrations as registration}
			<h2>Påmeldingsinfo for {registration.event.name}</h2>
			{#if ticketingOpen(registration) && !registration.payments?.length}
				<div class="nopay">
					Du har ikke betalt for din billett enda<br />
					<button
						class="payment-button"
						on:click={() => purchaseTicket(registration.id)}
					>
						Kjøp billett
					</button>
					<a class="newlink" target="_blank" href="/kjopsbetingelser"
						>Se kjøpsbetingelser for billetter</a
					>
				</div>
			{/if}
			{#if !isEditingRegistration}
				<p class="profile__info">
					<span class="profile__label">Billettype:</span>
					{ticketTypeMap[registration.ticketType]}
				</p>
				<p class="profile__info">
					<span class="profile__label">Jeg deltar med:</span>
				</p>
				<ul class="profile__info">
					{#each registration.consoles.split(',') as console}
						<li>{consoleMap[console]}</li>
					{/each}
				</ul>
				<p class="profile__info">
					{registration.bringsOwnChair
						? 'Jeg tar med egen stol'
						: 'Jeg tar IKKE med egen stol'}
				</p>
				<p class="profile__info">
					{registration.public
						? 'Min deltakelse er offentlig'
						: 'Min deltakelse er anonym og vises ikke for andre på nett'}
				</p>
				<button on:click={editRegistration} class="action-button">
					Rediger påmelding
				</button>
			{:else}
				<form
					on:submit|preventDefault={() =>
						onEditRegistration(registration.id)}
				>
					<label>
						<input
							type="checkbox"
							bind:checked={registration.bringsOwnChair}
						/>
						Jeg tar med egen stol
					</label>
					{#if !registration.bringsOwnChair}
						<p class="remark">
							Dersom du ikke kan ta med egen stol vil du få utdelt
							en stol. <br />
							Merk at disse ikke er særlig komfortabel over lengre
							tid.<br /> (Anbefales å ta med pute eller annet mykt
							å sitte på)
						</p>
					{/if}
					<label>
						<input
							type="checkbox"
							bind:checked={registration.public}
						/>
						Vis min gamertag/kallenavn offentlig i deltakerlisten
					</label>
					<br />
					<div class="console-container">
						<p class="field-name">Jeg deltar med:</p>
						<ul class="ks-cboxtags">
							{#each Object.entries(consoles) as [key, value], index}
								<li>
									<input
										type="checkbox"
										id={`console-${key}`}
										value={key}
										bind:checked={checkedConsoles[key]}
									/><label for={`console-${key}`}
										>{value}</label
									>
								</li>
							{/each}
						</ul>
					</div>
					<button type="submit" class="action-button">
						Rediger påmelding
					</button>
					<br />
				</form>
			{/if}
			<br /><br />
			<div
				class="profile-section"
				class:green={registration.payments?.length}
				class:red={ticketingOpen(registration) &&
					!registration.payments?.length}
			>
				<div class="profile-section__collapsed">
					<div class="profile-section__left">
						<h3 class="profile-section__title">Betaling</h3>
						<p class="profile-section__info">
							{#if !registration.payments?.length}
								Har ikke betalt for deltakelsen enda. {#if !ticketingOpen(registration)}(Betaling
									er ikke tilgjengelig enda){/if}
								{registration.payments}
							{:else}
								{#each registration.payments as payment}
									Innbetaling registrert
									{new Date(
										payment.paid_at
									).toLocaleDateString('nob')} via
									<b
										>{getHumanPaymentMethod(
											payment.method
										)}</b
									><br />
								{/each}
							{/if}
						</p>
					</div>
					{#if ticketingOpen(registration) && !registration.payments?.length}
						<button class="expandButton" on:click={expandPayment}>
							<div class="profile-section__right">
								<p class="profile-section__plus">+</p>
								<p class="profile-section__plus-label">
									Kjøp billett
								</p>
							</div>
						</button>
					{/if}
				</div>
				{#if paymentExpanded && ticketingOpen(registration)}
					<div class="profile-section__expanded">
						<h3>Kjøp billett</h3>
						<p>
							<b>Billettpris: 550 NOK</b>
						</p>
						<button
							class="payment-button"
							on:click={() => purchaseTicket(registration.id)}
						>
							Kjøp billett
						</button>
						<a
							class="newlink"
							target="_blank"
							href="/kjopsbetingelser"
							>Se kjøpsbetingelser for billetter</a
						>
						<!-- <div class="ticketselector">
						<div class="tickettype outofstock" class:outofstock={} class: on:click={selectTicket()}>
							<h4>Standard (Early bird)</h4>
							<p>Kun <b>40</b> igjen</p>
							<p>450 NOK</p>
						</div>
						<div class="tickettype">
							<h4>Standard</h4>
							<p>Begrenset antall</p>
							<p class="tickettype__price">550 NOK</p>
						</div>
					</div> -->
					</div>
				{/if}
			</div>
			<br />
			<div
				class="profile-section"
				class:green={registration.armbands?.length}
				class:red={!registration.armbands?.length}
			>
				<div class="profile-section__left">
					<h3 class="profile-section__title">Armbånd</h3>
					<p class="profile-section__info">
						{#if !registration.armbands?.length}
							Har ikke fått armbånd enda {#if hasPaid(registration)}
								(Armbånd deles ut i døren mot vist legitimasjon
								og kjøpt billett){:else}(Kun deltakere som har
								betalt kan hente armbånd){/if}
						{:else}
							{#each registration.armbands as armband}
								Armbånd utstedet
								{new Date(
									armband.created_at
								).toLocaleDateString('nob')}
							{/each}
						{/if}
					</p>
				</div>
				<!-- <div class="profile-section__right">
					<p class="profile-section__plus">+</p>
					<p class="profile-section__plus-label">Registrer nå</p>
				</div> -->
			</div>
			<br />
			<div
				class="profile-section"
				class:green={registration.seat}
				class:red={ticketingOpen(registration) && !registration.seat}
			>
				<div class="profile-section__collapsed">
					<div class="profile-section__left">
						<h3 class="profile-section__title">Setevalg</h3>
						<p class="profile-section__info">
							{#if !registration.seat}
								Har ikke valgt sete (Kun deltakere med betalte
								billetter kan velge sete)
							{:else}
								Valgt sete: <b
									>{tableDefinitions[registration.seat.row]
										.name} Sete
									{registration.seat.seat + 1}</b
								>
							{/if}
						</p>
					</div>
					{#if hasPaid(registration) || registration.seat}
						<a class="whitelink" href="/seatreg">
							<div class="profile-section__right">
								<p class="profile-section__plus">+</p>
								<p class="profile-section__plus-label">
									ENDRE SETEVALG
								</p>
							</div>
						</a>
					{/if}
				</div>
			</div>
		{/each}
		{#if userProfile.crewApplications && userProfile.crewApplications.length}
			<h2>Mine crewsøknader</h2>
			{#each userProfile.crewApplications as application}
				<div
					class:green={application.isReviewed &&
						application.isApproved}
					class:red={application.isReviewed &&
						!application.isApproved}
					class="profile-section"
				>
					<div class="profile-section__left">
						<h3 class="profile-section__title">
							Søknad som: 1. {crewTypeMap[application.wantedCrew]}
							{#if application.wantedCrew2}2. {crewTypeMap[
									application.wantedCrew2
								]}{/if}
							{#if application.wantedCrew3}3. {crewTypeMap[
									application.wantedCrew3
								]}{/if}
						</h3>
						<div class="profile-section__info">
							{#if !application.isReviewed}
								<p>Søknaden har enda ikke blitt vurdert</p>
							{:else if application.isApproved}
								<p>
									Søknaden din er godkjent, velkommen til
									crew!
								</p>
							{:else}
								<p>Søknaden din ble avvist.</p>
								<!-- <p>
									<b>Begrunnelse:</b>
									{application.reviewReason}
								</p> -->
							{/if}
						</div>
					</div>
				</div>
				<br />
			{/each}
		{/if}
	{/if}
</div>

<style>
	p {
		max-width: 1200px;
		margin-top: 0;
	}

	.nopay {
		background-color: #e03939;
		padding: 20px 40px;
		border-radius: 5px;
		display: inline-block;
	}

	.payment-notice {
		background-color: #ccc;
		color: black;
		padding: 20px;
		border-radius: 5px;
	}

	.whitelink {
		color: white;
	}
	.newlink {
		color: #02b4ff;
		text-decoration: underline;
	}

	label {
		display: block;
	}
	.error {
		color: #ff3333;
	}

	.expandButton {
		background: none;
		border: none;
		color: white;
	}

	input[type='text'],
	input[type='tel'],
	input[type='date'],
	input[type='email'],
	input[type='password'],
	select {
		display: block;
		padding: 10px 20px;
		font-size: 16px;
		border-radius: 5px;
		border: none;
		min-width: 270px;
		margin-top: 2px;
		margin-bottom: 10px;
	}

	.profile__info {
		margin: 5px 0;
	}
	.profile__label {
		font-weight: bold;
	}

	.profile-section {
		background: #999;
		padding: 20px;
		align-items: center;
		max-width: 800px;
		border-radius: 5px;
		flex-wrap: wrap;
	}
	.profile-section__collapsed {
		display: flex;
		align-items: center;
		max-width: 800px;
		border-radius: 5px;
		flex-wrap: wrap;
	}
	.profile-section.red {
		background: #e03939;
	}
	.profile-section.green {
		background: #43b122;
	}

	.profile-section__title {
		font-size: 25px;
		margin: 0;
	}
	.profile-section__info {
		margin: 0;
	}
	.profile-section__left {
		flex: 1;
	}
	.profile-section__right {
		text-align: center;
		margin-right: 10px;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}
	.profile-section__left {
		flex: 1;
		flex-basis: 1;
	}
	.profile-section__right:hover {
		cursor: pointer;
		color: #f0f0f0;
	}
	.profile-section__right:active {
		transform: translateY(1px);
	}
	.profile-section__plus {
		font-size: 30px;
		margin: 0;
		line-height: 1;
	}
	.profile-section__plus-label {
		margin: 0;
	}

	.profile-section__expanded {
		margin-top: 20px;
	}

	.ticketselector {
		display: flex;
	}

	.tickettype {
		padding: 10px;
		background-color: #999;
		margin-right: 10px;
		border-radius: 5px;
		min-width: 200px;
	}
	.tickettype.selected {
		background: #43b122;
	}
	.tickettype.outofstock {
		opacity: 0.6;
	}

	.tickettype__price {
		font-size: 18px;
		font-weight: bold;
	}
	.tickettype p,
	.tickettype h4 {
		margin: 0;
	}

	.payment-button {
		padding: 10px 20px;
		border: none;
		background-color: royalblue;
		color: white;
		border-radius: 5px;
		font-size: 18px;
	}
	.payment-button:hover {
		background-color: rgb(101, 138, 250);
		cursor: pointer;
	}
	.payment-button:active {
		transform: translateY(1px);
	}

	input[type='checkbox'] {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 10px;
	}

	.console-container {
		max-width: 550px;
	}
	ul.ks-cboxtags {
		list-style: none;
		padding-left: 0;
	}
	ul.ks-cboxtags li {
		display: inline;
	}
	ul.ks-cboxtags li label {
		display: inline-block;
		background-color: rgba(255, 255, 255, 0.9);
		border: 2px solid rgba(139, 139, 139, 0.3);
		color: #adadad;
		border-radius: 8px;
		white-space: nowrap;
		margin: 3px 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		transition: all 0.2s;
	}

	ul.ks-cboxtags li label {
		padding: 8px 12px;
		cursor: pointer;
	}

	ul.ks-cboxtags li label::before {
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		font-size: 12px;
		padding: 2px 6px 2px 2px;
		content: '\f067';
		transition: transform 0.3s ease-in-out;
	}

	ul.ks-cboxtags li input[type='checkbox']:checked + label::before {
		content: '\f00c';
		transform: rotate(-360deg);
		transition: transform 0.3s ease-in-out;
	}

	ul.ks-cboxtags li input[type='checkbox']:checked + label {
		border: 2px solid #f81b1b;
		background-color: #d41212;
		color: #fff;
		transition: all 0.2s;
	}

	ul.ks-cboxtags li input[type='checkbox'] {
		display: absolute;
	}
	ul.ks-cboxtags li input[type='checkbox'] {
		position: absolute;
		opacity: 0;
	}
</style>
