<script lang="ts">
	import {activities} from '../stores/activities';

	activities.fetch();
</script>

<div class="page-content">
	<h1>Aktiviteter</h1>
	<p>
		Dette er en oversikt over diverse aktiviteter som er planlagt for TGEX
		v3
	</p>
	{#each $activities as activity}
		<h2 class="activity-heading">{activity.name}</h2>
		<p class="activity-label">AKTIVITET</p>
		<div class="activity-content content">
			{@html activity.content}
		</div>
		<br />
	{/each}
	<p><b>Flere aktiviteter vil annonseres fortløpende</b></p>
</div>

<style>
	.activity-heading {
		font-size: 30px;
		margin-bottom: 0;
	}
	.activity-label {
		margin-top: -10px;
	}
</style>
