<div class="page-content">
	<h2><b>REGLER</b></h2>
	<p>
		<b>Strømforbruk: </b>Det er ikke tillatt å ta med store stereoanlegg,
		kjøleskap, vannkokere eller annet strømkrevende utstyr. TGEX crew sørger
		for musikk og underholdning. Varmt/kokende vann fåes gratis i kiosken.<br
		/><br />
	</p>
	<p>
		<b>Tyveri: </b>Alle tilfeller av tyveri/forsøk på tyveri vil bli
		politianmeldt. I tillegg fører dette til utestengelse fra arrangementet.<br
		/><br />
	</p>
	<p>
		<b>Personlig utstyr: </b>Alt utstyr deltakeren tar med på TGEX er
		deltakers ansvar. TGEX tar ikke ansvar for hverken tap av data, tyveri,
		force majeure, eller skader på utstyr.<br /><br />
	</p>
	<p>
		<b>Nettverk: </b>Nettverk og internettforbindelse er et privilegium og
		skal ikke misbrukes. Dette betyr at all bruk som strider mot norsk lov,
		herunder distribusjon og spredning av programvare uten
		lisensrettigheter, spredning av ulovlig pornografi, hacking eller forsøk
		på hacking av lokalt nett og internett eller spredning av virus er
		strengt forbudt.<br /><br />
	</p>
	<p>
		<b>Røyking: </b>Det er ikke tillatt å røyke inne i hallen. Det kan
		røykes utendørs, men IKKE foran hovedinngangen.<br /><br />
	</p>
	<p>
		<b>Rusmidler: </b>All bruk av rusmidler er strengt forbudt og berusede
		personer vil bli bortvist.<br />
	</p>
	<p>
		<b>Salg av koffeinprodukter: </b> Salg eller markedsføring av koffeinprodukter
		(koffeintabletter, koffeinholdig drikker og andre energidrikker hvor koffeinnivået
		er høyere enn den lovlige grensen) som ikke er godkjent for salg i Norge,
		er ikke tillatt. Dette gjelder selv om det er privat fra person til person.
		Hva du tar med til eget bruk, er ditt ansvar. Vi oppfordrer alle til å forholde
		seg til gjeldende regler.
	</p>
	<p>
		<b>ID:</b> Alle deltakere må kunne identifisere seg. Dette er nødvendig for
		å ha komplett oversikt over alle deltakere. Gyldig bevis er bankkort, førerkort,
		studentkort eller pass.
	</p>
	<p>
		<b>Ingen laserpenner eller lufthorn: </b>For å sørge for at alle har det
		gøy og ingen får noen skader på TGEX, så erbruken av laserpekere og
		lufthorn forbudt. Om vi oppdager at disse benyttes, vil de bli
		konfiskert av Sikkerhetscrewet.
	</p>
	<p>
		<b>
			Alle deltakere har et personlig ansvar for å gjøre seg forstått med-
			og følge disse reglene. Brudd kan føre til utestengelse og
			deltakeravgiften vil i så tilfelle ikke refunderes.
		</b>
	</p>
	<p><a href="/kjopsbetingelser">Se kjøpsbetingelser for billettkjøp</a></p>
</div>
