<script lang="ts">
	import {news} from '../stores/news';

	news.fetch();
</script>

<div class="page-content">
	{#each $news as article}
		<h1 class="activity-heading">{article.title}</h1>
		<p class="activity-label">NYHETSSAK</p>
		<div class="activity-content content">
			<div class="excerpt">{article.excerpt}</div>
			<br />
			<div>{@html article.content}</div>
		</div>
		<br /><br />
	{/each}
	<p><b>Flere nyheter vil publiseres fortløpende</b></p>
</div>

<style>
	.excerpt {
		font-style: italic;
	}
	.activity-label {
		margin: 0;
		font-weight: 600;
	}
</style>
