<script lang="ts">
	import {forgotPassword} from '../api/authentication';

	let email = '';
	let error = '';
	let sent = false;

	const onSubmit = async () => {
		try {
			await forgotPassword(email);
			sent = true;
		} catch (e) {
			error = e.error;
		}
	};
</script>

<div class="page-content">
	{#if sent}
		<div class="login-page">
			<div class="login-form">
				<h1>Epost om nullstilling av passord sendt!</h1>
				<br />
				<a class="link" href="/login">
					Klikk her for å returnere til innloggingsskjermen
				</a>
				<br />
			</div>
		</div>
	{:else}
		<div class="login-page">
			<div class="login-form">
				<h1>Glemt passord</h1>
				<form on:submit|preventDefault={onSubmit}>
					<div>
						<label>
							Email
							<input type="email" bind:value={email} />
						</label>
						<a class="link" href="/login">
							Kan du allerede passordet ditt?
						</a>
						<br />
						<div class="error">{error}</div>
						<br />
						<button type="submit">Send</button>
					</div>
				</form>
			</div>
		</div>
	{/if}
</div>
