<script lang="ts">
	import type {RegistrationData} from '../global';
	import {TicketType} from '../constants';

	import RequireUser from '../components/RequireUser.svelte';
	import {postRegistration} from '../api/registration';
	import {onMount} from 'svelte';
	import {user} from '../stores/user';
	import {event} from '../stores/event';
	import {router} from 'tinro';
	import {getNextEvent} from '../api/event';
	let nextEvent;
	const getEventInfo = async () => {
		nextEvent = await getNextEvent();
	};
	$: nextEvent = nextEvent;
	getEventInfo();

	$: registerError = '';

	let acceptsTerms = false;
	let registrationCompleted = false;

	const registrationData: RegistrationData = {
		ticketType: TicketType.Standard,
		public: false,
		consoles: '',
		bringsOwnChair: false,
		wantsNewsletter: false,
	};

	onMount(async () => {
		await event.fetch();

		await user.fetch();
		console.log($user);
		if ($user?.registrations.length) {
			registrationCompleted = true;
		}
	});

	const getCheckedConsoles = () => {
		let consoles = [];
		Object.entries(checkedConsoles).forEach(([key, value]) => {
			if (value) consoles.push(key);
		});

		return consoles.join(',');
	};

	const onRegistration = async () => {
		registerError = '';
		if (!acceptsTerms) {
			registerError =
				'Du må godkjenne reglementet for å registrere din påmelding';
			return;
		}
		registrationData.consoles = getCheckedConsoles();
		const registrationResponse = await postRegistration(registrationData);
		if (registrationResponse.error) {
			registerError = registrationResponse.error;
			return;
		}

		registrationCompleted = true;
		router.goto('/profile');
	};

	const consoles = {
		pc: 'PC',
		ps5: 'Playstation 5',
		ps4: 'Playstation 4',
		xbs: 'Xbox Series X/S',
		xbone: 'Xbox One',
		switch: 'Nintendo Switch',
		other: 'Annet',
	};
	const checkedConsoles = {
		pc: false,
		ps5: false,
		ps4: false,
		xbs: false,
		xbone: false,
		switch: false,
		other: false,
	};
</script>

{#if nextEvent}
	<div class="page-content">
		<h1>{nextEvent.name} - Påmelding</h1>
		{#if !nextEvent.registrationsOpen}
			<p>
				Påmelding for {nextEvent.name} er ikke åpnet enda.
				<a href="/">Gå tilbake til forside</a>
			</p>
		{:else if registrationCompleted}
			<div class="registered">
				<p>
					Din påmelding for TGEX er registrert! Vi gleder oss til å se
					deg der.
				</p>
				<p>
					Du kan se detaljer om din påmelding fra <a href="/profile">
						din profil
					</a>
				</p>
			</div>
		{:else}
			<p>
				Dette er påmeldingsskjemaet til TGEX V3. Her registrerer du din
				påmelding og informasjonen vi trenger. <br />Betaling og
				seteregistrering vil du kunne gjøre fra din profilside når vi
				åpner for det på et senere tidspunkt. <br />Vi vil sende ut en
				epost og annonsere på sosiale medier når dette åpner
			</p>
			<h2>Informasjon om Deltakelse</h2>
			<label>
				<input
					type="checkbox"
					bind:checked={registrationData.bringsOwnChair}
				/>
				Jeg tar med egen stol
			</label>
			{#if !registrationData.bringsOwnChair}
				<p class="remark">
					Dersom du ikke kan ta med egen stol vil du få utdelt en
					stol. <br />
					Merk at disse ikke er særlig komfortabel over lengre tid.<br
					/> (Anbefales å ta med pute eller annet mykt å sitte på)
				</p>
			{:else}
				<br /><br /><br />
			{/if}
			<br />
			<div class="console-container">
				<p class="field-name">Jeg deltar med:</p>
				<ul class="ks-cboxtags">
					{#each Object.entries(consoles) as [key, value], index}
						<li>
							<input
								type="checkbox"
								id={`console-${key}`}
								value={key}
								bind:checked={checkedConsoles[key]}
							/><label for={`console-${key}`}>{value}</label>
						</li>
					{/each}
				</ul>
			</div>
			<br />
			<!-- <div>
			<p class="field-name">Billettype:</p>
			<label>
				<input
					type="radio"
					bind:group={registrationData.ticketType}
					name="ticketType"
					value={TicketType.Standard}
					checked
				/> Standard ( 550 kr )
			</label>
		</div>
		<br /> -->
			<br />

			<RequireUser>
				<h2>Annet</h2>
				<label>
					<input
						type="checkbox"
						name="wants_newsletter"
						id="wants_newsletter"
						bind:checked={registrationData.wantsNewsletter}
					/> Jeg vil motta nyhetsbrev fra TGEX.
				</label>
				Uavhengig av denne innstillingen vil du få epost vedrørende din brukerprofil
				og én oppdatering når billettbetaling åpner
				<br /><br />
				<label>
					<input
						type="checkbox"
						bind:checked={registrationData.public}
					/>
					Vis min gamertag/kallenavn offentlig i deltakerlisten
				</label>
				<br />
				<label>
					<input
						type="checkbox"
						name="accepts_terms"
						id="accepts_terms"
						bind:checked={acceptsTerms}
						required
					/>
					Jeg samtykker til TGEX sitt
					<a href="/rules" target="_blank">reglement</a>
					og
					<a href="/privacy" target="_blank">personvernsregler</a>
				</label>
				<br />
				{#if registerError}
					<p class="error">{registerError}</p>
				{/if}
				<button class="action-button neutral" on:click={onRegistration}>
					Send påmelding
				</button>
			</RequireUser>
			<br /><br />
		{/if}
	</div>
{/if}

<style>
	p {
		max-width: 1200px;
		margin-top: 0;
	}

	h2 {
		margin: 0;
	}

	.error {
		color: red;
	}

	.field-name {
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.5px;
	}

	.registered {
		font-size: 18px;
	}

	a,
	.link {
		color: #45a0e8;
	}
	.link:hover {
		cursor: pointer;
	}

	.remark {
		font-style: italic;
		font-size: 14px;
	}

	label {
		display: block;
	}

	input[type='checkbox'] {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 10px;
	}

	.console-container {
		max-width: 550px;
	}
	ul.ks-cboxtags {
		list-style: none;
		padding-left: 0;
	}
	ul.ks-cboxtags li {
		display: inline;
	}
	ul.ks-cboxtags li label {
		display: inline-block;
		background-color: rgba(255, 255, 255, 0.9);
		border: 2px solid rgba(139, 139, 139, 0.3);
		color: #adadad;
		border-radius: 8px;
		white-space: nowrap;
		margin: 3px 0px;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		transition: all 0.2s;
	}

	ul.ks-cboxtags li label {
		padding: 8px 12px;
		cursor: pointer;
	}

	ul.ks-cboxtags li label::before {
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		font-size: 12px;
		padding: 2px 6px 2px 2px;
		content: '\f067';
		transition: transform 0.3s ease-in-out;
	}

	ul.ks-cboxtags li input[type='checkbox']:checked + label::before {
		content: '\f00c';
		transform: rotate(-360deg);
		transition: transform 0.3s ease-in-out;
	}

	ul.ks-cboxtags li input[type='checkbox']:checked + label {
		border: 2px solid #f81b1b;
		background-color: #d41212;
		color: #fff;
		transition: all 0.2s;
	}

	ul.ks-cboxtags li input[type='checkbox'] {
		display: absolute;
	}
	ul.ks-cboxtags li input[type='checkbox'] {
		position: absolute;
		opacity: 0;
	}
</style>
