<div class="page-content">
	<h1>Om TGEX</h1>
	<h2>HVA ER TGEX</h2>
	<p>
		TGEX - Tromsø Gaming Experience, er et dataparty som arrangeres årlig i
		Tromsø. Dataparty - eller LAN, om du vil - har eksistert i Tromsø siden
		1998 og er et arrangement der deltakerne tar med egen PC eller konsoll
		for en langhelg med spill og morro i TUIL- hallen. Vår visjon er å skape
		en samlingsplass for barn og ungdom i alle aldre som har interesse for
		spill, data, teknologi og sosiale aktiviteter.
	</p>

	<p>
		<b>TGEX v4 vil bli arrangert 9.-12. Mars 2023<br /></b>
		TGEX v3 ble arrangert 10.-13. Mars 2022<br />
		TGEX v2 ble arrangert 3.-6. okt 2019<br />
		TGEX v1 ble arrangert 4.-7. okt 2018
	</p>
	<p>TGEX er non-profit og arrangeres av frivillige ildsjeler.</p>
	<h2 id="contact">Kontaktinfo</h2>
	<p>
		Trenger du hjelp med noe, har du flere måter du kan nå oss. Vår
		anbefalte måte å ta kontakt er ved å sende en melding til oss på <a
			href="https://m.me/tromsogex"
		>
			Facebook Messenger</a
		>. Om du er i vår discord server (lenke på forsiden) kan du sende en av
		oss admins en melding direkte, eller du kan sende oss en epost. Se
		epostadresser under hver person lengre ned på denne siden
	</p>
	<h2>HVEM STÅR BAK TGEX?</h2>
	<h3>SVART ADMINS</h3>
	<p>
		Svart admins har sorte gensere med ADMIN og kallenavn på ryggen. De med
		svart genser er hovedarrangører for TGEX og kan svare på det meste som
		har med arrangementet å gjøre.
	</p>
	<h4 class="crew-admin">Brynjar Johansen</h4>
	<p>
		Etter 7 år som arrangør av TromsøLAN var Brynjar ivrigst på å fortsette
		LAN-kulturen i Tromsø. Brynjar dannet TGEX i september 2018 og
		arrangerte V1 allerede oktober samme år. Han har hovedansvar for TGEX i
		sin helhet og er ansvarlig for tech-crewet.<br />
		<a href="mailto:brynjar@tgex.no">brynjar@tgex.no</a>
	</p>
	<h4 class="crew-admin">Tina C. H. Andersen</h4>
	<p>
		Tina var med å stifte TGEX sammen med Brynjar. Hun har vært ivrig
		LAN-deltaker i mange år før hun tredde over i arrangørrollen. Tina har
		hovedansvar for kiosken, samt produksjon av alt grafisk innhold til
		TGEX. Hun er også ansvarlig for markedsføring av TGEX.<br />
		<a href="mailto:tina@tgex.no">tina@tgex.no</a>
	</p>
	<h4 class="crew-admin">Simen Fjellstad</h4>
	<p>
		Simen ble med i TGEX under v1 som en del av tech-crew. Han markerte seg
		raskt ved å vise kompetanse vi sårt trengte i TGEX, og var allerede sort
		admin på v2. Innehar kunnskaper om nettverk, programmering,
		turnerings-hosting og videoproduksjon. Simen har hovedansvar for
		Gamecrew, web og streamproduksjon.<br />
		<a href="mailto:simen@tgex.no">simen@tgex.no</a>
	</p>
	<h4 class="crew-admin">Daniel Jensen</h4>
	<p>
		Daniel er ringreven blant oss sorte. Har vært på LAN i en mannsalder og
		innehar god kompetanse med ungdom gjennom jobb. Daniel har hovedansvar
		for security crewet og er en meget potent fotograf.<br />
		<a href="mailto:daniel@tgex.no">daniel@tgex.no</a>
	</p>
	<h4 class="crew-admin">Anders Haga</h4>
	<p>
		Anders er vår LAN-veteran og har nok flere LAN under beltet enn de
		fleste av oss kombinert. Har sterk kompetanse på nettverksikkerhet og
		bidrar med erfaring og kunnskap i alle avdelinger hos oss.<br />
	</p>
	<br />
	<h3>ADMINS</h3>
	<h4 class="crew-kiosk">Elise</h4>
	<p>
		Elise har ansvar for TGEX Kiosk og TGEX Kafè. Hun er utdannet kokk og
		står for planlegging, produksjon og gjennomføring av alt som hører til
		mat og drikke.
	</p>
	<h4 class="crew-tech">Christian</h4>
	<p>
		Christian er ansvarlig for det tekniske og sterkstrøm. Christian er
		utdannet kjølemontør, men har god kontroll på det meste innenfor strøm
		og teknisk. Har ansvar for å planlegge og sørge for at alle deltakere
		har rikelig med strøm tilgjengelig og forhindre overbelasting på
		bordradene.
	</p>
	<h4 class="crew-game">Mats/Siri/Vegard</h4>
	<p>
		Denne trioen har ansvar for gjennomføring av våre esport-konkurranser.
		Alle tre har rikelig med LAN-erfaring og har i oppgave å gjennomføre
		alle våre høyest premierte konkurranser. Alt i fra regler til
		server-hosting til kommunikasjon med over 150 spillere.
	</p>
	<h4 class="crew-entertainment">Bjørn</h4>
	<p>
		Bjørn har ansvar for underholdningen som skjer på scenen. Jobber til
		daglig med kreativ teknologi på Tvibit og har lang erfaring som
		scenehost og streamer.
	</p>
	<h4 class="crew-security">Morten</h4>
	<p>
		Morten er vår sikkerhetssjef og har vært det i mange år. Har hatt ansvar
		for security både under TromsøLAN og TGEX. Har ansvar for at regler
		overholdes og at deltakerne kan trygt tilbringe en helg i TUIL-hallen.
	</p>
	<br />
	<h3>CREW</h3>
	<h4 class="crew-admin">Admin (SORT GENSER)</h4>
	<p>Admin har hovedansvar for arrangering av TGEX.</p>
	<h4 class="crew-kiosk">Kiosk (LILLA GENSER)</h4>
	<p>
		Kiosk-crewet står for salg og produksjon av alt drikkelig og spiselig på
		TGEX.
	</p>
	<h4 class="crew-tech">Tech (RØD GENSER)</h4>
	<p>
		Tech-crewet står for rigging av strøm samt hjelpe deltakerne med
		tekniske utfordringer underveis.
	</p>
	<h4 class="crew-game">Game (BLÅ GENSER)</h4>
	<p>Game crewet arrangerer våre største spillkonkurranser under TGEX.</p>
	<h4 class="crew-entertainment">Entertainment (TURKIS GENSER)</h4>
	<p>
		Entertainment har som mål å underholde deltakerne våre med diverse
		aktiviteter på scenen som f.eks kahoot, stolleken, kreative konkurranser
		m.m.
	</p>
	<h4 class="crew-security">Security (GUL GENSER)</h4>
	<p>
		Security passer på at alle følger ordensregler, toalettfasiliteter er
		rene og pene samt båndsjekk ved inngangen. Gjennom helgen er det alltid
		tre fra security på vakt som har forskjellige roller i hallen. Security
		har gul genser på som gjør at de er godt synlig i hallen.
	</p>
</div>

<style>
	h3 {
		margin-top: 20px;
		margin-bottom: 5px;
		font-size: 23px;
	}
	h4 {
		margin-top: 25px;
		margin-bottom: 0px;
	}
	p {
		margin-top: 0;
	}

	.crew-admin {
		color: #6ff838;
	}
	.crew-game {
		color: #2d73f7;
	}
	.crew-entertainment {
		color: #6dddff;
	}
	.crew-kiosk {
		color: #ca00ca;
	}
	.crew-security {
		color: #ffff00;
	}
	.crew-tech {
		color: #ff3939;
	}
</style>
