<script lang="ts">
	export let visible = false;
	export let title = '';
	export let onClose = () => {};
	export const open = () => (visible = true);
	export const close = () => {
		visible = false;
		onClose();
	};
</script>

{#if visible}
	<div class="background" on:click|self={close}>
		<div class="content">
			<div class="header">
				<h2>{title}</h2>
				<button class="close-button" on:click={close} />
			</div>
			<slot />
		</div>
	</div>
{/if}

<style>
	.background {
		position: fixed;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.33);
		top: 0;
		left: 0;
	}
	.header {
		display: flex;
		justify-content: space-between;
		margin: 5px 0 24px 0;
	}
	h2 {
		margin: 0;
	}
	.close-button {
		border: 0;
		background: transparent;
		cursor: pointer;
		width: 24px;
	}
	.content {
		color: black;
		box-sizing: border-box;
		padding: 20px 24px;
		background: white;
		width: 100%;
		max-width: 400px;
		max-height: calc(100% - 32px);
		border-radius: 4px;
		overflow-y: auto;
	}
	.button-bar {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
		margin-top: 10px;
	}
	.errortext {
		color: #f85555;
		font-size: 16px;
	}
	@media (max-width: 600px) {
		.content {
			width: 100%;
		}
	}
</style>
